import { mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  computed: {
    ...mapState(['alerts'])
  },

  watch: {
    'alerts.fetching' (newVal) {
      if (newVal) {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
          },
          html: '<small>Fetching..</small>',
          allowOutsideClick: false,
          showCloseButton: false,
          background: '#318ade',
          backdrop: `#318ade`,
          color: '#fff'
        });
      } else {
        Swal.close();
      }
    },

    'alerts.saving' (newVal) {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        width: 200,
        // background: 'rgba(16, 196, 232, 1)',
        didOpen: () => {
          Swal.showLoading();
        },
        showClass: {
          popup: 'animate__animated animate__slideInRight'
        },
        hideClass: {
          popup: 'animate__animated animate__slideOutRight'
        }
      });

      if (newVal) {
        Toast.fire({
          icon: 'info',
          title: 'Saving..',
          // color: '#fff',
          color: '#4e4f4f'
        });
      } else {
        Toast.close();
      }
    },

    'alerts.submitting' (newVal) {
      if (newVal) {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
          },
          html: '<small>Submitting..</small>',
          allowOutsideClick: false,
          showCloseButton: false
        });
      } else {
        Swal.close();
      }
    },

    'alerts.invalid' (newVal) {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        width: 300,
        background: 'rgba(252, 186, 3, 1)',
        // didOpen: () => {
        //   Swal.showLoading()
        // },
        icon: 'warning',
        showClass: {
          popup: 'animate__animated animate__slideInRight'
        },
        hideClass: {
          popup: 'animate__animated animate__slideOutRight'
        }
      });

      if (newVal) {
        Toast.fire({
          icon: 'info',
          title: 'Some of the fields are invalid. (Required or Unique)',
          color: '#fff'
        });
      } else {
        Toast.close();
      }
    },

    'alerts.unauthorized' (newVal) {
      if (newVal) {
        Swal.fire({
          icon: 'warning',
          html: `You are unauthorized to do this action in your current session. 
                Please try to <a href="/logout">relogin</a>.`,
          allowOutsideClick: false,
          showCloseButton: false
        }).then(result => {
          if (result.isConfirmed) {
            location.reload();
          }
        });
      } else {
        Swal.close();
      }
    },

    'alerts.submitted' (newVal) {
      if (newVal) {
        Swal.fire({
          icon: 'success',
          text: 'Successfully submitted!',
          allowOutsideClick: false,
          showCloseButton: false
        });
      } else {
        Swal.close();
      }
    },

    'alerts.error' (newVal) {
      if (newVal) {
        Swal.fire({
          icon: 'warning',
          text: 'Something went wrong. Please try again. If error persists, contact administrator.',
          allowOutsideClick: false,
          showCloseButton: false
        }).then(result => {
          if (result.isConfirmed) {
            location.reload();
          }
        });
      } else {
        Swal.close();
      }
    },

    'alerts.processing' (newVal) {
      if (newVal) {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
          },
          html: '<small>Processing..</small>',
          allowOutsideClick: false,
          showCloseButton: false
        });
      } else {
        Swal.close();
      }
    },

    'alerts.processed' (newVal) {
      if (newVal) {
        Swal.fire({
          icon: 'success',
          text: 'Process completed!',
          allowOutsideClick: false,
          showCloseButton: false
        });
      } else {
        Swal.close();
      }
    },

    'alerts.customMessage' (params) {
      if (params[0]) {
        Swal.fire({
          icon: params[1],
          text: params[2],
          allowOutsideClick: false,
          showCloseButton: true
        });
      } else {
        Swal.close();
      }
    },

    'alerts.customMessageError' (params) {
      if (params[0]) {
        Swal.fire({
          icon: 'warning',
          text: params[1],
          allowOutsideClick: false,
          showCloseButton: false
        }).then(result => {
          if (result.isConfirmed) {
            location.reload();
          }
        });
      } else {
        Swal.close();
      }
    },

    'alerts.createDraft' (newVal) {
      if (newVal) {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
          },
          html: '<small>Creating draft...</small>',
          allowOutsideClick: false,
          showCloseButton: true
        });
      } else {
        Swal.close();
      }
    }
  }
};
