export function dataTables () {
  function loadDataTable (
    tableId = null,
    url,
    columns,
    columnDefs,
    invisible,
    selectable,
    search,
    orderColumn,
    exportButtons
  ) {
    const dt = $(`#${tableId}`).DataTable({
      dom:
        "<'row'<'col-sm-12 col-md-6'B><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-3'i><'col-sm-12 col-md-3'l><'col-sm-12 col-md-6'p>>",
      buttons: exportButtons, // "copy", "excel", "pdf", "colvis"
      processing: true,
      serverSide: true,
      lengthChange: false,
      responsive: true,
      scrollX: false,
      ajax: {
        url: url,
        dataType: 'json',
        method: 'GET',
        data: function (data) {
          data.tableId = tableId;

          if (typeof invisible !== 'undefined') {
            $(`#${tableId}`).DataTable().columns(invisible).visible(false);
          }
        },
        error: function (xhr, error, code) {
          console.log('error?', xhr, error, code);
          Swal.fire({
            icon: 'info',
            title: 'Data Processing',
            text: 'Please click to refresh the page.',
            confirmButtonText: `Refresh`
          }).then(result => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      },
      columns: columns,
      columnDefs: columnDefs,
      select: selectable,
      order: orderColumn,
      searching: search,
      lengthChange: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 100, 200],
        [10, 25, 50, 100, 200]
      ],
      language: {
        lengthMenu: 'Showing &nbsp; _MENU_ &nbsp; items per page'
      },
      initComplete: function (settings, json) {
        $(`#${tableId}_wrapper .dt-button`).removeClass('dt-button');

        // Store datatable request in session for query extension
        sessionStorage.removeItem('datatable-request');
        sessionStorage.setItem(
          'datatable-request',
          JSON.stringify(json.queryString)
        );
      },
      drawCallback: function (settings) {
        let currentPage = parseInt(
          $(`#${tableId}_paginate .paginate_button.current`).text()
        );
        let totalPage = $(
          `#${tableId}_paginate a:not(.first, .previous, .next, .last)`
        ).length;
        let totalItems = settings.json.recordsTotal;

        let pageRange = Array.from(
          {
            length: totalPage
          },
          (x, i) => i + 1
        );

        let pageOpts = '';
        pageRange.forEach(el => {
          if (el === currentPage) {
            pageOpts +=
              '<option selected value="' + el + '">' + el + '</option>';
          } else {
            pageOpts += '<option value="' + el + '">' + el + '</option>';
          }
        });

        let selectField =
          '<select class="form-control form-control-sm custom_paginate_select" style="width:50px;">' +
          pageOpts +
          '</select>';

        $('.dataTables_info')
          .html(
            'Page &nbsp;' +
              selectField +
              ' &nbsp;of ' +
              totalPage +
              ' (' +
              totalItems +
              ' total items)'
          )
          .addClass('d-flex justify-content-start align-items-center p-0');

        $(document).on('change', '.custom_paginate_select', function () {
          var pageNum = $(this).val() - 1;
          var oTable = $(`#${tableId}`).dataTable();
          oTable.fnPageChange(pageNum);
        });
      }
    });
  }

  function reloadDataTable (
    newTableId,
    url,
    columns,
    columnDefs,
    invisible,
    selectable,
    search,
    orderColumn,
    exportButtons
  ) {
    $(`#${newTableId}`).DataTable().destroy();
    loadDataTable(
      newTableId,
      url,
      columns,
      columnDefs,
      invisible,
      selectable,
      search,
      orderColumn,
      exportButtons
    );
  }
  
  return {
    loadDataTable,
    reloadDataTable
  };
}

export function dataTableQuery () {
  function getParsed (query = {}) {
    let queryObj = {};

    const datatableRequest = sessionStorage.getItem('datatable-request');
  
    if (datatableRequest) {
      const parsedData = JSON.parse(datatableRequest);
  
      // Convert the 'columns' array to the correct format
      const columnsData = parsedData.columns.map((column, index) => ({
        [`columns[${index}][data]`]: column.data,
        [`columns[${index}][name]`]: column.name,
        [`columns[${index}][searchable]`]: column.searchable,
        [`columns[${index}][orderable]`]: column.orderable,
        [`columns[${index}][search][value]`]: column.search.value,
        [`columns[${index}][search][regex]`]: column.search.regex
      }));
  
      const orderData = parsedData.order.map((order, index) => ({
        [`order[${index}][column]`]: order.column,
        [`order[${index}][dir]`]: order.dir
      }));
  
      // Combine all parts of the query string
      queryObj = {
        ...query,
        type: parsedData.type,
        draw: parsedData.draw,
        ...Object.assign({}, ...columnsData),
        start: parsedData.start,
        length: parsedData.length,
        search: {
          value: parsedData.search.value,
          regex: parsedData.search.regex
        },
        ...Object.assign({}, ...orderData),
        tableId: parsedData.tableId,
        _: parsedData._
      };
    }
  
    return queryObj;
  }

  return {
    getParsed
  }
}
