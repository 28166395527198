import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      //
    }
  },
  
  computed: {
    ...mapState(['pettyCash', 'taxRates', 'alerts']),

    contactSource() {
      let typeStr = this.type;

      if (this.data) {
        typeStr = this.data.transaction_type;
      }

      if (typeStr === 'petty-cash') {
        this.pettyCash.page.list = 'petty-cash';
        return ['is_employee'];
      }

      if (typeStr === 'spend-cash') {
        this.pettyCash.page.list = 'spend-cash';
        return ['is_supplier'];
      }
    },

    contactState: {
      get() {
        return this.pettyCash.data.parent.contact_id;
      }
    },

    dateState: {
      get() {
        return this.pettyCash.data.parent.system_recorded_date;
      },
      set(value) {
        this.pettyCash.data.parent.system_recorded_date = moment(value).format('YYYY-MM-DD');
      }
    },

    referenceState: {
      get() {
        return this.pettyCash.data.parent.reference;
      },
      set(value) {
        this.pettyCash.data.parent.reference = value;
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.pettyCash.data.parent.is_tax_exclusive
      },
      set(value) {
        this.pettyCash.data.parent.is_tax_exclusive = value
        
        this.pettyCash.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.pettyCash.data.lines[index].tax_rate_id = '4'
          } else {
            this.pettyCash.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value
        this.$store.dispatch('pettyCash/setTaxAmounts')
        this.$store.dispatch('pettyCash/setTotals')
      }
    },
  },

  methods: {
    changeContactSelect(index, name, value) {
      this.pettyCash.data.parent.contact_id = value
      this.clearParentInvalids('contact_id')
      this.alerts.invalid = false;
    },

    changeDynamicTableField(index, name, value) {
      this.clearLineInvalids(name, index)
      this.pettyCash.data.lines[index][name] = value;
      this.$store.dispatch('pettyCash/setSubTotals')
      this.$store.dispatch('pettyCash/setTaxAmounts')
      this.$store.dispatch('pettyCash/setTotals')
      this.setGrandTotals()
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids(name, index)
      this.pettyCash.data.lines[index][name] = value;
      this.$store.dispatch('pettyCash/setTaxAmounts')
      this.$store.dispatch('pettyCash/setTotals')
      this.setGrandTotals()
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.pettyCash.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids(key, index) {
      if (this.pettyCash.lineValidations.length > 0) {
        if (this.pettyCash.lineValidations[index].hasOwnProperty(key)) {
          delete this.pettyCash.lineValidations[index][key];
          this.alerts.invalid = false;
        }
      }
    },

    clearParentInvalids(key) {
      if (this.pettyCash.parentValidations.hasOwnProperty(key)) {
        delete this.pettyCash.parentValidations[key];
        this.alerts.invalid = false;
      }
    },

    setGrandTotals() {
      /** @info On load, get default tax rates */
      this.$store.dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.pettyCash.data.parent.is_tax_exclusive
        })
        .then(response => {
          this.$store.dispatch('pettyCash/setGrandTotals');
        })
        .catch(error => { console.log(error) })
    },
  },

  mounted() {
    //
  },
}