<template>
  <button hidden type="button" class="btn btn-primary" id="loadingCoverTrigger" data-bs-toggle="modal" data-bs-target="#loadingCover">
    LoadingTrigger
  </button>
  
  <div class="modal" id="loadingCover" tabindex="-1" aria-labelledby="loadingCoverLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body bg-primary d-flex justify-content-center align-items-center">
          <button hidden type="button" id="loadingCloseTrigger" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="d-block">
            <div class="cover sk-folding-cube d-block">
              <div class="cover sk-cube1 sk-cube"></div>
              <div class="cover sk-cube2 sk-cube"></div>
              <div class="cover sk-cube4 sk-cube"></div>
              <div class="cover sk-cube3 sk-cube"></div>
            </div>
            <h5 class="text-white">Getting ready..</h5>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import { mapState } from 'vuex';
  import alerts from '@/mixins/alerts';
  
  export default {
    name: 'LoadingComponent',
    computed: {
      ...mapState(['alerts']),
    },

    watch: {
      'alerts.loading'(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            const loading = document.getElementById('loadingCoverTrigger');
            loading.click();
          });
        } else {
          this.$nextTick(() => {
            const close = document.getElementById('loadingCloseTrigger');
            close.click();
          });
        }
      },
    },

    mounted() {
      if (this.alerts.loading) {
        const loading = document.getElementById('loadingCoverTrigger');
        loading.click();
      }

      if (!this.alerts.loading) {
        const close = document.getElementById('loadingCloseTrigger');
        close.click();
      }
    },
  }
</script>
<style>
  .cover.sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }

  .cover.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); 
  }

  .cover.sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    -webkit-animation: cover-sk-foldCubeAngle 2.4s infinite linear both;
            animation: cover-sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }

  .cover.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);
  }

  .cover.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);
  }

  .cover.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);
  }

  .cover.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }

  .cover.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; 
  }

  .cover.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }

  @-webkit-keyframes cover-sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    } 
  }

  @keyframes cover-sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    }
  }
</style>