export function translations(key) {
  const keys = key.split('.');
  let value = this.$page.props.translations;

  for (const k of keys) {
    if (value.hasOwnProperty(k)) {
      value = value[k];
    } else {
      return key;
    }
  }

  return value;
}