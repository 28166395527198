import { Inertia } from '@inertiajs/inertia';

export const inertiaXHR = {
  // Function to show the custom loading bar
  showCustomProgressBar() {
    const container = document.createElement('div');
    container.className = 'custom-progress-container';
    const progress = document.createElement('div');
    progress.className = 'custom-progress-bar';
    container.appendChild(progress);
    document.body.appendChild(container);

    const progressBar = document.querySelector('.custom-progress-bar');
    if (progressBar) {
        progressBar.classList.add('active');
    }
  },

  // Function to hide the custom loading bar
  hideCustomProgressBar() {
    const container = document.querySelector('.custom-progress-container');
    if (container) {
        document.body.removeChild(container);
    }
  },
  
  visit(url, options = {}) {
    this.showCustomProgressBar();

    setTimeout(() => {
      const defaultOptions = {
        method: 'get',
        data: {},
        replace: false,
        preserveState: false,
        preserveScroll: false,
        only: [],
        headers: {},
        errorBag: null,
        forceFormData: false,
        onCancelToken: cancelToken => {},
        onCancel: () => {},
        onBefore: visit => {},
        onStart: visit => {},
        onProgress: progress => {},
        onSuccess: page => {
          this.hideCustomProgressBar();
        },
        onError: errors => {},
        onFinish: visit => {},
      };
  
      const addOrUpdateOptions = { ...defaultOptions, ...options };
  
      Inertia.visit(url, addOrUpdateOptions);
    }, 100);
  }
};