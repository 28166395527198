import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { processors } from '../../../functions/processors';
import { formatter } from '@/functions/formatter';
import constants from '../../../constants';

let appPath = pathResolver.primaryPaths('app');
let tenantPath = pathResolver.primaryPaths('tenant');
let modulePath = pathResolver.primaryPaths('module');
let tabPath = pathResolver.primaryPaths('tab');

export default {
  namespaced: true,

  state: {
    // data: {},
    // validations: [],
    data: {
      paymentsParent: {},
      paymentsLines: [],
      groupPayments: []
    },
    parentValidations: [],
    lineValidations: [],
  },

  mutations: {
    setLine (state, { line, type }) {
      state.data.paymentsLines.push(line);
    },
  },

  actions: {
    async getParentColumns ({}) {
      return await axiosRequest.get(
        `/${appPath}/${tenantPath}/states/purchase_payments`,
        { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
      );
    },

    async getLinesColumns ({}) {
      return await axiosRequest.get(
        `/${appPath}/${tenantPath}/states/purchase_payment_lines`,
        { except: ['created_at', 'updated_at', 'deleted_at'] }
      );
    },

    async setLineColumns ({ dispatch, state, commit }, type) {
      let newLine = await dispatch('getLinesColumns');

      if (typeof type === 'undefined') {
        newLine.type = '';
      } else {
        newLine.type = type;
      }

      commit('setLine', { line: newLine, type: type });

      const lastIndex = state.data.paymentsLines.length - 1;

      return {
        index: lastIndex,
        line: newLine,
        lines: state.data.paymentsLines
      };
    },

    removePaymentLine ({ state }, index) {
      state.data.paymentsLines.splice(index, 1);
    },

    removeGroupLine ({ state }, index) {
      state.data.groupPayments.splice(index, 1);
    },

    async setGroupLine ({ dispatch, state, commit }, type) {
      let parentColumns = await dispatch('getParentColumns');
      let lineColumns = await dispatch('getLinesColumns');
      
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('groupCode')) {
        parentColumns.group_code = urlParams.get('groupCode');
      }

      parentColumns = [parentColumns].map((parent, index) => {
        parent.recorded_date = '';
        parent.purchase = {};
        parent.purchase.grand_total = formatter.currency(0);
        parent.purchase.total_tax = formatter.currency(0);
        parent.purchase.total_payments_remaining = formatter.currency(0);
        
        if (type === 'services') {
          parent.purchase.is_tax_exclusive = true;
        }

        return parent;
      });
      
      let money = lineColumns;
      let prepaid = lineColumns;
      let vat = lineColumns;
      let realized = lineColumns;
      
      let newLine = [{
        money: money,
        prepaid: prepaid,
        vat: vat,
        realized: realized,
      }];
      
      let paymentsColumns = newLine.map((type, index) => {
        const moneyType = { ...type.money };
        moneyType.amount = 0;
        moneyType.base_tax_amount = 0;
        moneyType.deferred_tax_amount = 0;
        moneyType.type = constants.COLLECTION_TYPE.MONEY_COLLECTION;
        type.money = moneyType;

        const prepaidType = { ...type.prepaid };
        prepaidType.amount = 0;
        prepaidType.base_tax_amount = 0;
        prepaidType.deferred_tax_amount = 0;
        prepaidType.type = constants.COLLECTION_TYPE.PREPAID_TAX;
        type.prepaid = prepaidType;

        const vatType = { ...type.vat };
        vatType.amount = 0;
        vatType.base_tax_amount = 0;
        vatType.deferred_tax_amount = 0;
        vatType.type = constants.COLLECTION_TYPE.VAT_WITHHELD;
        type.vat = vatType;

        const realizedType = { ...type.realized };
        realizedType.amount = 0;
        realizedType.base_tax_amount = 0;
        realizedType.deferred_tax_amount = 0;
        realizedType.type = constants.COLLECTION_TYPE.REALIZED_VAT;
        type.realized = realizedType;

        return type
      });
      
      state.data.groupPayments.push({
        paymentsParent: parentColumns[0],
        paymentsLines: paymentsColumns[0]
      });
    },

    async setPayment ({ dispatch, state }, params) {
      state.data.paymentsParent = params.data;

      state.data.paymentsParent.payment_date =
        params.data.payment_date;

      state.data.paymentsParent.receipt_type = params.data.receipt_type;

      state.data.paymentsParent.receipt_number_prefix =
        params.data.receipt_number_prefix;

      state.data.paymentsParent.receipt_number_series =
        params.data.receipt_number_series;

      state.data.paymentsParent.payment_total =
        params.data.payment_total;

      state.status = params.data.status;

      await dispatch('setLineColumns');
      console.log(params)
      const lines = params.data.lines.map((line, index) => {
        line.type = parseInt(line.type);
        return line;
      });

      state.data.paymentsLines = params.data.lines;
    },

    async getBillById({}, id) {
      const urlParams = new URLSearchParams(window.location.search);
      let groupCodeParam = '';
      if (urlParams.has('groupCode')) {
        groupCodeParam = `&groupCode=${urlParams.get('groupCode')}`;
      }

      return await axiosRequest.get(
        `/${appPath}/${tenantPath}/purchases/bills-to-pay/${id}?billOnly=true${groupCodeParam}`
      );
    },
    
    async getPayments({ commit, dispatch, state }, param) {
      return await axiosRequest.get(`/${appPath}/${tenantPath}/purchases/bills-to-pay/${param.id}/payments?json=true`);
    },

    async deletePayment ({}, params) {
      await axiosRequest.delete(
        `/${appPath}/${tenantPath}/purchases/bills-to-pay/${params.purchaseId}/payments/${params.id}`,
        params
      );
    },

    async voidPayment ({}, params) {
      params.status = 'void',
      await axiosRequest.put(
        `/${appPath}/${tenantPath}/purchases/bills-to-pay/${params.purchaseId}/payments/${params.id}`,
        params
      );
    },

    async store({ commit, dispatch, state, rootState }, purchase) {
      return await axiosRequest
        .post(
          `/${appPath}/${tenantPath}/purchases/bills-to-pay/${purchase}/payments/store`,
          {
            parent: state.data.paymentsParent,
            lines: state.data.paymentsLines
          }
        )
        .then(response => {
          const myTimeout = setTimeout(function () {
            rootState.alerts.saving = false;
          }, 1000);

          if (response.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;

            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.lineErrors;
            let validatedRows = processors.validateRows(
              Object.values(lineErrors)
            );
            state.lineValidations = validatedRows;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response;
          } else {
            rootState.alerts.submitting = false;
            rootState.alerts.submitted = true;

            return response;
          }
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },

    async storeGroup ({ state, rootState }, param) {
      return await axiosRequest
        .post(
          `/${appPath}/${tenantPath}/purchases/bills-to-pay/group-payments/store/${param.type}`,
          state.data.groupPayments
        )
        .then(response => {
          console.log('submit res', response)
          if (response.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;

            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.lineErrors;
            state.lineValidations = lineErrors;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response.message;
          } else {
            rootState.alerts.submitting = false;
            rootState.alerts.submitted = true;

            return response;
          }
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },

    async updateGroup ({ state, rootState }, param) {
      let qStr = `?status=${param.status}&formRequest=true`;
      if (typeof param.status === 'undefined') {
        qStr = `?formRequest=true`;
      }

      return await axiosRequest.put(
        `/${appPath}/${tenantPath}/purchases/${param.type}/group-payments/${param.receiptType}/${param.groupCode}${qStr}`,
        state.data.groupPayments
      ).then((response) => {
        if (response.message === 'invalid') {
          /**
           * @info Set validation boolean individual fields
           * */
          const parentErrors = response.parentErrors;
          let validatedParents = processors.validate(parentErrors);
          state.parentValidations = validatedParents.keys;

          /**
           * @info Set validation boolean for dynamic row fields
           * */
          const lineErrors = response.lineErrors;
          state.lineValidations = lineErrors;

          setTimeout(function () {
            rootState.alerts.invalid = true;
          }, 1000);

          return response.message;
        } else {
          rootState.alerts.submitting = false;
          rootState.alerts.submitted = true;

          return response;
        }
      });
    },

    delete({ commit, dispatch, state }, param) {
      return axiosRequest.delete(`/${appPath}/${tenantPath}/purchases/bills-to-pay/${param.purchase}/payments/${param.payment}`);
    },

    async getPrepaidAccount({ dispatch }) {
      return await dispatch('accounts/getResourceBy', {
          url: `/${appPath}/${tenantPath}/resources/accounts?getAccounts=true&modulePath=sales`,
          types: [{ account_codes: 'income_tax' }]
        }, { root: true })
        .then(async response => {
          return await response.data[0].id;
        });
    },
  }
};
