<template>
  <button 
    type="button" 
    :class="`btn btn-${background} ${textColor ? 'text-' + textColor : ''} m-0 ${!noIcon ? 'me-3 py-1 px-3' : ''} ${!action ? 'py-2' : ''} ${!text ? 'shadow-none' : ''}`"
    data-bs-toggle="tooltip"
    data-bs-placement="bottom"
    :title="tooltip ? tooltip : (action ? actions[action].text : '')"
    @click="handleClick"
  >
    <div class="d-flex align-items-center justify-content-center">
      <span v-if="!this.$slots.icon && !noIcon">
        <i :class="`${actions[action]?.icon} ${text ? 'fs-6' : 'fs-4'}`"></i>
      </span>
      <slot name="icon" v-if="this.$slots.icon && !noIcon"></slot>
      <span class="px-2" v-if="text">{{ text }}</span>
    </div>
  </button>
</template>
<script>
  export default {
    name: 'Buttons',
    props: {
      action: String,
      noIcon: {
        type: Boolean,
        default: false,
      },
      background: {
        type: String,
        default: 'default',
      },
      textColor: String,
      text: {
        type: String,
        default: null,
      },
      tooltip: String,
    },
    data() {
      return {
        actions: {
          create: {
            text: 'Create',
            icon: 'bi bi-file-earmark-plus-fill',
          },
          edit: {
            text: 'Edit',
            icon: 'bi-pencil-square',
          },
          delete: {
            text: 'Delete',
            icon: 'bi bi-trash',
          }, 
          view: {
            text: 'View',
            icon: 'bi bi-eye',
          },
          export: {
            text: 'Export',
            icon: 'bi bi-file-earmark-arrow-down-fill',
          },
        }
      }
    },
    methods: {
      handleClick() {
        this.$emit('handleClick');
      }
    }
  };
</script>
<style></style>
