export function basicScrollableY() {
  const basicScrollY = document.querySelectorAll('.perfect-scroll-basic-y');
  
  basicScrollY.forEach(container => {
    const ps = new PerfectScrollbar(container, {
      suppressScrollX: true,
    });
  });
}

export function basicScrollableX() {
  const basicScrollX = document.querySelectorAll('.perfect-scroll-basic-x');
  
  basicScrollX.forEach(container => {
    const ps = new PerfectScrollbar(container, {
      suppressScrollY: true,
    });
  });
}

export function initializeScrollableContainers() {
  const scrollableContainers = document.querySelectorAll('.perfect-scroll-container');
  
  scrollableContainers.forEach(container => {
    container.style.overflowX = 'auto';
    container.style.suppressScrollY  = true;
    container.style.position = 'relative';

    const ps = new PerfectScrollbar(container);
  });
}