import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { processors } from '../../../functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    page: {
      list: 'all',
      form: false,
      autosave: false,
    },
    data: {
      parent: {},
      lines: [],
    },
    parentValidations: [],
    lineValidations: [],
  },

  mutations: {
    setLine(state, { line }) {
      state.data.lines.push(line);
    },
  },

  actions: {
    async setParentColumns({ state }) {
      const parent = await axiosRequest.get(
        `/${app}/${tenant}/states/purchases`,
        {
          except: ['id', 'created_at', 'updated_at', 'deleted_at'],
        }
      );

      state.data.parent = parent;
    },

    async setLineColumns({ commit }) {
      const line = await axiosRequest.get(
        `/${app}/${tenant}/states/purchase_lines`,
        {
          except: ['id', 'created_at', 'updated_at', 'deleted_at'],
        }
      );

      commit('setLine', { line });
    },

    async setDataToState({ dispatch, state }, data) {
      if (typeof data !== 'undefined') {
        const parent = _.omit(data, 'lines');
        
        await dispatch('setParentColumns').then(() => {
          state.data.parent = parent;
        });

        await dispatch('setLineColumns').then(() => {
          const lines = data.lines;
          if (lines.length > 0) {
            state.data.lines = lines;
          }
        });
      }
    },

    setLineNoTax({ state }, index) {
      setTimeout(() => {
        state.data.lines[0].tax_rate_id = '4';
      }, 500);
    },

    store({ state, rootState }, params) {
      let postUrl = `/${app}/${tenant}/purchases/petty-cash/store`;
      
      if (params.hasOwnProperty('advanceType')) {
        postUrl = `/${app}/${tenant}/purchases/petty-cash/${params.id}/${state.data.parent.transaction_type}/store`;
      }

      return axios
        .post(postUrl, {
          parent: state.data.parent,
          lines: state.data.lines,
          status: params.status,
        })
        .then((response) => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);

          if (response.data.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.data.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;

            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.data.lineErrors;
            let validatedRows = processors.validateRows(lineErrors);
            state.lineValidations = validatedRows;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 500);

            rootState.alerts.saving = false;
            return response.data.message;
          }

          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    update({ rootState, state }, params) {
      let qStr = ``;

      if (typeof params.status !== 'undefined') {
        qStr = `?status=${params.status}&statusOnly=true`;
      }

      return axiosRequest
        .put(
          `/${app}/${tenant}/purchases/advance-payments/${params.id}/update${qStr}`,
          state.data
        )
        .then((response) => {
          if (response.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;

            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.lineErrors;
            state.lineValidations = lineErrors;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response.message;
          } else {
            rootState.alerts.submitting = false;
            rootState.alerts.submitted = true;

            return response;
          }
        });
    },

    delete({ commit, dispatch, state }, id) {
      let url = `/${app}/${tenant}/purchases/advance-payments/${id}`;

      if (state.data.parent.transaction_type === 'return') {
        url = `/${app}/${tenant}/purchases/advance-payments/${id}/return/${state.data.parent.id}`;
      }

      if (state.data.parent.transaction_type === 'reimburse') {
        url = `/${app}/${tenant}/purchases/advance-payments/${id}/reimburse/${state.data.parent.id}`;
      }

      return axiosRequest.delete(url);
    },

    setSubTotals({ state }) {
      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price;
        const subTotal = unitPrice * quantity;

        state.data.lines[index]['sub_total'] = parseFloat(subTotal).toFixed(2);
      });
    },

    setTaxAmounts({ state, rootState }) {
      const rateList = rootState.taxRates.rates;

      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price;
        const subTotal = unitPrice * quantity;
        const taxRate = lines[index].tax_rate_id;

        if (taxRate) {
          const percentage =
            _.find(Object.values(rateList), { id: parseInt(taxRate) })
              .percentage / 100;
          const taxAmount = subTotal * percentage;

          state.data.lines[index]['tax_amount'] =
            parseFloat(taxAmount).toFixed(2);
        }
      });
    },

    setTotals({ state }) {
      let lines = state.data.lines;

      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price;
        const subTotal = unitPrice * quantity;
        const taxRate = lines[index].tax_rate_id;
        const taxAmount = lines[index].tax_amount;

        const totalAmount = parseFloat(subTotal) + parseFloat(taxAmount || 0);

        state.data.lines[index]['total_amount'] =
          parseFloat(totalAmount).toFixed(2);
      });
    },

    setGrandTotals({ state, rootState }) {
      let rateList = rootState.taxRates.rates;
      let lines = state.data.lines;

      let all_sub_total = [];
      let all_vatable = [];
      let all_vat_exempt = [];
      let all_zero_rated = [];
      let all_no_tax = [];
      let all_vat = [];

      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price;
        const subTotal = unitPrice * quantity;
        const taxRate = lines[index].tax_rate_id;
        
        if (taxRate) {
          // Get all row subtotals
          all_sub_total.push(subTotal);

          // Get vatables
          const vatable = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'vatable',
          });
          if (vatable) {
            all_vatable.push(subTotal);
          }

          // Get vat exempt
          const vatExempt = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'vat_exempt',
          });
          if (vatExempt) {
            all_vat_exempt.push(subTotal);
          }

          // Get zero rated
          const zeroRated = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'zero_rated',
          });
          if (zeroRated) {
            all_zero_rated.push(subTotal);
          }
          
          // Get no tax
          const noTax = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'no_tax',
          });
          if (noTax) {
            all_no_tax.push(subTotal);
          }

          // Get vat
          const taxSelected = _.find(rateList, { id: parseInt(taxRate) });
          if (taxSelected) {
            const taxAmount = subTotal * (taxSelected.percentage / 100);
            all_vat.push(taxAmount);
          }
        }
      });

      const totalSubTotals = all_sub_total.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalVatables = all_vatable.reduce((sum, value) => sum + value, 0);
      const totalVatExempt = all_vat_exempt.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalZeroRated = all_zero_rated.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalNoTax = all_no_tax.reduce((sum, value) => sum + value, 0);
      const totalVat = all_vat.reduce((sum, value) => sum + value, 0);
      const totalGross =
        totalVatables + totalVatExempt + totalZeroRated + totalNoTax + totalVat;

      state.data.parent.sub_total = parseFloat(totalSubTotals).toFixed(2);
      state.data.parent.total_vatable = parseFloat(totalVatables).toFixed(2);
      state.data.parent.total_vat_exempt =
        parseFloat(totalVatExempt).toFixed(2);
      state.data.parent.total_zero_rated =
        parseFloat(totalZeroRated).toFixed(2);
      state.data.parent.total_no_tax = parseFloat(totalNoTax).toFixed(2);
      state.data.parent.total_tax = parseFloat(totalVat).toFixed(2);
      state.data.parent.grand_total = parseFloat(totalGross).toFixed(2);
    },
  }
}