import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { processors } from '../../../functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    tab: '',
    page: {
      list: 'promissory',
      form: false,
      autosave: false
    },
    data: {
      parent: {},
      lines: []
    },
    parentValidations: [],
    lineValidations: [],
    loanNumbers: [],
    loanOptionNumber: []
  },

  mutations: {
    setLine (state, { line }) {
      state.data.lines.push(line);
    },

    removeLine (state, { index }) {
      state.data.lines.splice(index, 1);
    }
  },

  actions: {
    async getParentColumns ({}) {
      return await new Promise((resolve, reject) => {
        axios
          .get(`/${app}/${tenant}/states/loans`, {
            params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
          })
          .then(async response => {
            resolve(response.data);
          })
          .catch(function (error) {
            if (error.response !== 200) {
              window.location.reload();
            }
            console.log(error.response);
            reject();
          });
      });
    },

    async getLinesColumns ({}) {
      return await new Promise((resolve, reject) => {
        axios
          .get(`/${app}/${tenant}/states/loan_lines`, {
            params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
          })
          .then(async response => {
            resolve(response.data);
          })
          .catch(function (error) {
            if (error.response !== 200) {
              window.location.reload();
            }
            console.log(error.response);
            reject();
          });
      });
    },

    async setParentColumns ({ dispatch, state }) {
      const parent = await dispatch('getParentColumns');
      state.data.parent = parent;
    },

    async setLineColumns ({ dispatch, commit }) {
      const line = await dispatch('getLinesColumns');
      commit('setLine', { line });
    },

    async setLineColumnsOnly ({ dispatch, commit, state }) {
      const line = await dispatch('getLinesColumns');

      if (parseInt(state.data.parent.is_tax_exclusive) === 0) {
        line.tax_rate_id = '4';
      }

      if (state.data.parent.transaction_type === 'loan-expense') {
        line.tax_rate_id = '2';
      }

      commit('setLine', { line });
    },

    removeLineColumnsOnly ({ dispatch, commit }, index) {
      commit('removeLine', { index });
      dispatch('setGrandTotals');
    },

    setLineNoTax ({ state }, index) {
      setTimeout(() => {
        state.data.lines[0].tax_rate_id = '4';
      }, 500);
    },

    async setDataToState ({ dispatch, state }, data) {
      if (typeof data !== 'undefined') {
        const parent = _.omit(data, 'lines');

        await dispatch('setParentColumns').then(() => {
          state.data.parent = parent;
        });

        await dispatch('setLineColumns').then(() => {
          const lines = data.lines;
          if (lines.length > 0) {
            state.data.lines = lines;
          }
        });

        dispatch('setGrandTotals');
      }
    },

    store ({ state, rootState, dispatch }, params) {
      let postUrl = `/${app}/${tenant}/loans/${state.page.list}/store`;

      return axios
        .post(postUrl, {
          parent: state.data.parent,
          lines: state.data.lines,
          status: params.status
        })
        .then(response => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);

          dispatch('setValidation', response);

          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    update ({ state, rootState, dispatch }, params) {
      let postUrl = `/${app}/${tenant}/loans/${state.page.list}/${state.data.parent.id}`;

      return axios
        .put(postUrl, {
          parent: state.data.parent,
          lines: state.data.lines,
          status: params.status
        })
        .then(response => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);

          dispatch('setValidation', response);

          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    delete ({ commit, dispatch, state }) {
      return axiosRequest.delete(
        `/${app}/${tenant}/loans/${state.page.list}/${state.data.parent.id}`
      );
    },

    setValidation ({ state, rootState }, response) {
      if (response.data.message === 'invalid') {
        /**
         * @info Set validation boolean individual fields
         * */
        const parentErrors = response.data.parentErrors;
        let validatedParents = processors.validate(parentErrors);
        state.parentValidations = validatedParents.keys;

        /**
         * @info Set validation boolean for dynamic row fields
         * */
        const lineErrors = response.data.lineErrors;
        let validatedRows = processors.validateRows(lineErrors);
        state.lineValidations = validatedRows;

        setTimeout(function () {
          rootState.alerts.invalid = true;
        }, 500);

        rootState.alerts.saving = false;
        return response.data;
      }
    },

    setSubTotals ({ state }) {
      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0;
        const subTotal = unitPrice * quantity;

        state.data.lines[index]['sub_total'] = parseFloat(subTotal).toFixed(2);
      });
    },

    setTaxAmounts ({ state, rootState }) {
      const cachedWithholding = localStorage.getItem('withholdingRateList');
      let rateList = rootState.taxRates.rates;
      let withholdingRateList = rootState.withholding.withholdingRates;

      if (
        cachedWithholding !== '[]' &&
        cachedWithholding !== '' &&
        cachedWithholding !== null
      ) {
        withholdingRateList = JSON.parse(cachedWithholding);
      }

      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0;
        const subTotal = unitPrice * quantity;
        const taxRate = lines[index].tax_rate_id;
        const withholdingRate = lines[index].withholding_tax_rate_id;

        if (taxRate) {
          const percentage =
            _.find(Object.values(rateList), { id: parseInt(taxRate) })
              .percentage / 100;
          const taxAmount = subTotal * percentage;

          state.data.lines[index]['tax_amount'] =
            parseFloat(taxAmount).toFixed(2);
        }

        if (withholdingRate) {
          const withholdingPercentage =
            _.find(Object.values(withholdingRateList), {
              id: parseInt(withholdingRate)
            })?.tax_rate / 100;
          const withheldAmount = subTotal * withholdingPercentage;
          state.data.lines[index]['tax_withheld'] =
            parseFloat(withheldAmount).toFixed(2);
        } else {
          state.data.lines[index]['tax_withheld'] = parseFloat(0).toFixed(2);
        }
      });
    },

    setTotals ({ state }) {
      let lines = state.data.lines;

      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0;
        const subTotal = unitPrice * quantity;
        const taxRate = lines[index].tax_rate_id;
        const taxAmount = lines[index].tax_amount;
        const withholdingRate = lines[index].withholding_tax_rate_id;

        const totalAmount = parseFloat(subTotal) + parseFloat(taxAmount || 0);

        state.data.lines[index]['total_amount'] =
          parseFloat(totalAmount).toFixed(2);
      });
    },

    setGrandTotals ({ state, rootState }) {
      let cachedWithholding = localStorage.getItem('withholdingRateList');
      let rateList = rootState.taxRates.rates;
      let withholdingRateList = rootState.withholding.withholdingRates;
      let lines = state.data.lines;

      if (
        cachedWithholding !== '[]' &&
        cachedWithholding !== '' &&
        cachedWithholding !== null
      ) {
        withholdingRateList = JSON.parse(cachedWithholding);
      }

      let all_sub_total = [];
      let all_vatable = [];
      let all_vat_exempt = [];
      let all_zero_rated = [];
      let all_no_tax = [];
      let all_vat = [];
      let all_withheld = [];

      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0;
        const subTotal = unitPrice * quantity;
        const taxRate = lines[index].tax_rate_id;
        const withholdingRate = lines[index].withholding_tax_rate_id;

        if (taxRate) {
          // Get all row subtotals
          all_sub_total.push(subTotal);

          // Get vatables
          const vatable = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'vatable'
          });
          if (vatable) {
            all_vatable.push(subTotal);
          }

          // Get vat exempt
          const vatExempt = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'vat_exempt'
          });
          if (vatExempt) {
            all_vat_exempt.push(subTotal);
          }

          // Get zero rated
          const zeroRated = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'zero_rated'
          });
          if (zeroRated) {
            all_zero_rated.push(subTotal);
          }

          // Get no tax
          const noTax = _.find(rateList, {
            id: parseInt(taxRate),
            code: 'no_tax'
          });
          if (noTax) {
            all_no_tax.push(subTotal);
          }

          // Get vat
          const taxSelected = _.find(rateList, { id: parseInt(taxRate) });
          if (taxSelected) {
            const taxAmount = subTotal * (taxSelected.percentage / 100);
            all_vat.push(taxAmount);
          }
        }

        if (withholdingRate) {
          const withholdingSelected = _.find(withholdingRateList, {
            id: parseInt(withholdingRate)
          });
          const withholdingAmount =
            subTotal * (withholdingSelected.tax_rate / 100);
          all_withheld.push(withholdingAmount);
        }
      });

      const totalSubTotals = all_sub_total.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalVatables = all_vatable.reduce((sum, value) => sum + value, 0);
      const totalVatExempt = all_vat_exempt.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalZeroRated = all_zero_rated.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalNoTax = all_no_tax.reduce((sum, value) => sum + value, 0);
      const totalVat = all_vat.reduce((sum, value) => sum + value, 0);
      const totalWithheld = all_withheld.reduce((sum, value) => sum + value, 0);
      const totalGross =
        totalVatables +
        totalVatExempt +
        totalZeroRated +
        totalNoTax +
        totalVat -
        totalWithheld;

      state.data.parent.sub_total = parseFloat(totalSubTotals).toFixed(2);
      state.data.parent.total_vatable = parseFloat(totalVatables).toFixed(2);
      state.data.parent.total_vat_exempt =
        parseFloat(totalVatExempt).toFixed(2);
      state.data.parent.total_zero_rated =
        parseFloat(totalZeroRated).toFixed(2);
      state.data.parent.total_no_tax = parseFloat(totalNoTax).toFixed(2);
      state.data.parent.total_tax = parseFloat(totalVat).toFixed(2);
      state.data.parent.total_tax_withheld =
        parseFloat(totalWithheld).toFixed(2);
      state.data.parent.grand_total = parseFloat(totalGross).toFixed(2);
    }
  }
};
