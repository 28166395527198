import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      //
    }
  },

  computed: {
    ...mapState(['loanPayments', 'taxRates', 'alerts']),

    contactState: {
      get() {
        return this.loanPayments.data.parent.contact_id;
      },
      set(value) {
        this.loanPayments.data.parent.contact_id = value;
      }
    },

    dateState: {
      get() {
        return this.loanPayments.data.parent[this.name];
      },
      set(value) {
        this.loanPayments.data.parent[this.name] = moment(value).format('YYYY-MM-DD');
      }
    },

    referenceState: {
      get() {
        return this.loanPayments.data.parent.reference;
      },
      set(value) {
        this.loanPayments.data.parent.reference = value;
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.loanPayments.data.parent.is_tax_exclusive;
      },
      set(value) {
        this.loanPayments.data.parent.is_tax_exclusive = value
        
        this.loanPayments.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.loanPayments.data.lines[index].tax_rate_id = '4'
          } else {
            this.loanPayments.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value;
        
        this.$store.dispatch('loans/setTaxAmounts');
        this.$store.dispatch('loans/setTotals');
        this.setGrandTotals();
      }
    },
  },

  methods: {
    changeContactSelect(index, name, value) {
      this.loanPayments.data.parent.contact_id = value
    },

    changeDynamicTableField(index, name, value) {
      if(!['account_id','tax_rate_id'].includes(name)) {
        this.clearLineInvalids()

        if (
          [
            'unit_price',
            'sub_total',
            'tax_amount',
            'total_amount'
          ].includes(name)
        ) {
          /** Clean currency value */
          value = value?.replace(/,/g, '');
        }

        this.loanPayments.data.lines[index][name] = value;
        this.$store.dispatch('loanPayments/setSubTotals')
        this.$store.dispatch('loanPayments/setTaxAmounts')
        this.$store.dispatch('loanPayments/setTotals')
        this.setGrandTotals(index, name)
      }
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids()
      this.loanPayments.data.lines[index][name] = value;
      this.$store.dispatch('loanPayments/setTaxAmounts');
      this.$store.dispatch('loanPayments/setTotals');

      if(['tax_rate_id', 'withholding_tax_rate_id'].includes(name)) {
        this.setGrandTotals();
      }
    },

    setGrandTotals(index, name) {
      // On load, get default tax rates
      this.$store.dispatch('taxRates/getTaxRatesSelect')
        .then(() => {
          this.$store.dispatch('loanPayments/setGrandTotals')
        })
        .catch(error => { console.log(error) })
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.loanPayments.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids() {
      const lines = this.loanPayments.data.lines.map((line, index) => {
        delete line['invalid'];
        return line
      })

      this.loanPayments.data.lines = lines;
      this.alerts.invalid = false;
    },

    clearParentInvalids() {
      this.loanPayments.invalidParents = []
      this.alerts.invalid = false;
    },
  },
}