import { mapState } from 'vuex';
import selectResources from '@/mixins/selectResources';
import alerts from '@/mixins/alerts';

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      balanceState: 0,
    }
  },
  computed: {
    ...mapState(['liquidation']),

    contactSource() {
      if (this.advance.advance_type === 'employees') {
        this.liquidation.page.list = this.advance.advance_type;
        return ['is_employee'];
      }

      if (this.advance.advance_type === 'suppliers') {
        this.liquidation.page.list = this.advance.advance_type;
        return ['is_supplier'];
      }
    },

    contactState: {
      get() {
        return this.liquidation.data.parent.contact_id;
      }
    },

    dateState: {
      get() {
        return this.liquidation.data.parent.system_recorded_date;
      },
      set(value) {
        this.liquidation.data.parent.system_recorded_date = moment(value).format('YYYY-MM-DD');
      }
    },

    advanceDateSet() {
      return this.advance?.system_recorded_date
    },

    projectState: {
      get() {
        return this.liquidation.data.parent.project_id
      }
    },

    transactionNumber: {
      get() {
        return this.liquidation.data.parent.transaction_number;
      },
      set(value) {
        this.liquidation.data.parent.transaction_number = value;
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.liquidation.data.parent.is_tax_exclusive
      },
      set(value) {
        this.liquidation.data.parent.is_tax_exclusive = value
        
        this.liquidation.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.liquidation.data.lines[index].tax_rate_id = '4'
          } else {
            this.liquidation.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value
        this.$store.dispatch('billsToPay/setTaxAmounts')
        this.$store.dispatch('billsToPay/setTotals')
        this.setGrandTotals()
      }
    },
  },

  methods: {
    changeContactSelect(index, name, value) {
      this.liquidation.data.parent.contact_id = value
      this.clearParentInvalids('contact_id')
      this.alerts.invalid = false;
    },

    changeProjectSelect(index, name, value) {
      this.liquidation.data.parent.project_id = value
    },

    changeDynamicTableField(index, name, value) {
      if(!['account_id','tax_rate_id', 'withholding_tax_rate_id'].includes(name)) {
        this.clearLineInvalids(name, index);

        this.liquidation.data.lines[index][name] = value;
        this.$store.dispatch('liquidation/setSubTotals')
        this.$store.dispatch('liquidation/setTaxAmounts')
        this.$store.dispatch('liquidation/setTotals')
        
        this.setGrandTotals()
      }
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids(name, value)
      this.liquidation.data.lines[index][name] = value;
      
      this.$store.dispatch('liquidation/setTaxAmounts')
      this.$store.dispatch('liquidation/setTotals')
      this.setGrandTotals()
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.liquidation.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids(key, index) {
      if (this.liquidation.lineValidations.length > 0) {
        if (this.liquidation.lineValidations[index].hasOwnProperty(key)) {
          delete this.liquidation.lineValidations[index][key];
          this.alerts.invalid = false;
        }
      }
    },

    clearParentInvalids(key) {
      if (this.liquidation.parentValidations.hasOwnProperty(key)) {
        delete this.liquidation.parentValidations[key];
        this.alerts.invalid = false;
      }
    },

    setGrandTotals() {
      // On load, get default tax rates
      this.$store.dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.liquidation.data.parent.is_tax_exclusive
        })
        .then(response => {
          this.$store.dispatch('liquidation/setGrandTotals')
            .then(() => {
              const grandTotal = this.liquidation.data.parent.grand_total - this.liquidation.data.parent.total_tax_withheld;
              const balance = JSON.parse(app.dataset.page).props.balance;
              const advanceBalance = balance - grandTotal;
              this.liquidation.data.parent.advance_balance = parseFloat(advanceBalance).toFixed(2)
            });
        })
        .catch(error => { console.log(error) })
    },
  },
};
