import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      // advanceAccountSource: 'bank_accounts',
    }
  },
  
  computed: {
    ...mapState(['advancePayments', 'taxRates', 'alerts']),

    contactSource() {
      let typeStr = this.type;
      if (this.data) {
        typeStr = this.data.advance_type;
      }

      if (typeStr === 'employees') {
        this.advancePayments.page.list = 'employees';
        return ['is_employee'];
      }

      if (typeStr === 'suppliers') {
        this.advancePayments.page.list = 'suppliers';
        return ['is_supplier'];
      }
    },

    contactState: {
      get() {
        return this.advancePayments.data.parent.contact_id;
      }
    },

    dateState: {
      get() {
        return this.advancePayments.data.parent[this.name];
      },
      set(value) {
        this.advancePayments.data.parent[this.name] = moment(value).format('YYYY-MM-DD');
      }
    },

    transactionNumber: {
      get() {
        return this.advancePayments.data.parent.transaction_number;
      },
      set(value) {
        this.advancePayments.data.parent.transaction_number = value;
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.advancePayments.data.parent.is_tax_exclusive
      },
      set(value) {
        this.advancePayments.data.parent.is_tax_exclusive = value
        
        this.advancePayments.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.advancePayments.data.lines[index].tax_rate_id = '4'
          } else {
            this.advancePayments.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value
        this.$store.dispatch('advancePayments/setTaxAmounts')
        this.$store.dispatch('advancePayments/setTotals')
        // this.setGrandTotals()
      }
    },
  },

  watch: {
    'advancePayments.data.parent.advance_account_id'(newVal, oldVal) {
      this.clearParentInvalids('advance_account_id');
    },
  },

  methods: {
    changeContactSelect(index, name, value) {
      this.advancePayments.data.parent.contact_id = value
      this.clearParentInvalids('contact_id')
      this.alerts.invalid = false;
    },

    changeDynamicTableField(index, name, value) {
      this.clearLineInvalids(name, index)
      this.advancePayments.data.lines[index][name] = value;
      this.$store.dispatch('advancePayments/setSubTotals')
      this.$store.dispatch('advancePayments/setTaxAmounts')
      this.$store.dispatch('advancePayments/setTotals')
      this.setGrandTotals()
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids(name, index)
      this.advancePayments.data.lines[index][name] = value;
      this.$store.dispatch('advancePayments/setTaxAmounts')
      this.$store.dispatch('advancePayments/setTotals')
      this.setGrandTotals()
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.advancePayments.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids(key, index) {
      if (this.advancePayments.lineValidations.length > 0) {
        if (this.advancePayments.lineValidations[index].hasOwnProperty(key)) {
          delete this.advancePayments.lineValidations[index][key];
          this.alerts.invalid = false;
        }
      }
    },

    clearParentInvalids(key) {
      if (this.advancePayments.parentValidations.hasOwnProperty(key)) {
        delete this.advancePayments.parentValidations[key];
        this.alerts.invalid = false;
      }
    },

    setGrandTotals() {
      /** @info On load, get default tax rates */
      this.$store.dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.advancePayments.data.parent.is_tax_exclusive
        })
        .then(response => {
          this.$store.dispatch('advancePayments/setGrandTotals');
        })
        .catch(error => { console.log(error) })
    },
  },

  mounted() {
    //
  },
}