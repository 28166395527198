import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      isLoadingTotals: true,
    };
  },

  computed: {
    ...mapState(['banking', 'alerts']),

    contactState: {
      get() {
        return this.banking.data.transaction?.contact_id
      }
    },

    dateState: {
      get() {
        return this.banking.data.transaction?.[this.name];
      },
      set(value) {
        this.banking.data.transaction[this.name] = moment(value).format('YYYY-MM-DD');
      }
    },

    referenceState: {
      get() {
        return this.banking.data.transaction?.reference
      },
      set(value) {
        this.banking.data.transaction.reference = value;
      }
    },

    projectState: {
      get() {
        return this.banking.data.transaction?.project_id
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.banking.data.transaction?.is_tax_exclusive
      },
      set(value) {
        this.banking.data.transaction.is_tax_exclusive = value
        
        this.banking.data.transaction_lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.banking.data.transaction_lines[index].tax_rate_id = '4'
          } else {
            this.banking.data.transaction_lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value
        this.getTaxRates();
        this.setGrandTotals()
      }
    },
  },

  methods: {
    changeEmployeeSelect(index, name, value) {
      this.banking.data.transaction.contact_id = value
      this.clearParentInvalids()
      this.clearLineInvalids()
      this.alerts.invalid = false;
    },

    changeProjectSelect(index, name, value) {
      this.banking.data.transaction.project_id = value
    },

    changeDynamicTableField(index, name, value) {
      this.clearLineInvalids()
      this.banking.data.transaction_lines[index][name] = value;
      this.$store.dispatch('banking/setSubTotals')
      this.$store.dispatch('banking/setTaxAmounts')
      this.$store.dispatch('banking/setTotals')
      this.setGrandTotals(index, name)
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids()
      this.banking.data.transaction_lines[index][name] = value;
      this.$store.dispatch('banking/setTaxAmounts')
      this.$store.dispatch('banking/setTotals')
      this.setGrandTotals(index, name)
    },

    getTaxRates() {
        this.$store.dispatch('taxRates/getTaxRatesSelect', {
            taxExclusive: this.banking.data.transaction.is_tax_exclusive
          })
          .then(response => {
            this.$store.dispatch('banking/setTaxAmounts');
            this.$store.dispatch('banking/setTotals');
          })
          .catch(error => { console.log(error) })
    },

    setGrandTotals(index, name) {
      this.$store.dispatch('banking/setGrandTotals');
    },

    submit(type) {
      this.alerts.submitting = true;
      this.$store.dispatch("banking/storeSpendReceive", {
        'type': type,
        'bankAccount': this.bankAccount.id,
      }).then((response) => {
        if (response !== "invalid") {
          this.alerts.submitted = true;
          
          let app = this.app;
          let tenant = this.tenant;

          setTimeout(function () {
            window.location.href = `/${app}/${tenant}/banking/bank-accounts`;
          }, 1000);
        }
      });
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.banking.transactionLineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids() {
      const lines = this.banking.data.transaction_lines.map((line, index) => {
        delete line['invalid'];
        return line
      })

      this.banking.data.transaction_lines = lines;
      this.alerts.invalid = false;
    },

    clearParentInvalids() {
      this.alerts.invalid = false;
    },
  },

  mounted() {
    //
  },
};
