
import { mapState } from "vuex";
import { pathResolver } from "@/functions/pathResolver.js";

let app = pathResolver.primaryPaths("app");
let tenant = pathResolver.primaryPaths("tenant");

export default {
  data() {
    return {
      sourceOptions: [],
      resourceList: [''],
    };
  },

  computed: {
    ...mapState(['taxRates', 'accounts', 'withholding', 'banking', 'cash', 'alerts']),

    reactiveSourceOptions() {
      if (this.source === 'tax_rates') {
        options = this.taxRates.rateSelectAllData.map((res) => {
          return {
            id: res.id,
            value: `${res.name} (${Math.floor(res.percentage)})`
          };
        });
      } else {
        options = this.sourceOptions;
      }
      
      return options;
    },
  },

  watch: {
    source(newVal, oldVal) {
      if (newVal[2]) {
        if (!_.isEqual(newVal, oldVal)) {
          let thisSource = this.source;
          
          /**
           * @info Specific logic for bank transfer select fields
           */
          if (newVal === 'bank_accounts_transfer') {
            thisSource = 'bank_accounts';
          }
          
          /**
           * @info Specific logic for advance payments bank accounts
           */
          if (newVal === 'bank_accounts') {
            thisSource = newVal;
          }
          if (newVal === 'cash_accounts') {
            thisSource = newVal;
          }

          this.getResource(
            thisSource,
            this.$attrs.module,
            this.$attrs.taxExclusive,
          );
        }
      }
    },
  },

  methods: {
    getResource(source, module, isTaxExclusive) {
      const sourceParam = Array.isArray(source) ? source[0] : source;
      
      if (
        sourceParam !== undefined &&
        sourceParam !== null &&
        sourceParam !== ''
      ) {
        if (sourceParam === 'tax_rates') {
          this.getTaxRates(source, module, isTaxExclusive);
        }

        if (sourceParam === 'accounts') {
          this.getAccounts(source, module, isTaxExclusive);
        }

        if (sourceParam === 'withholding_rates') {
          this.getWithholdingRates(source, module);
        }
        
        if (sourceParam === 'bank_accounts' || sourceParam === 'cash_accounts') {
          this.getCashBankAccounts(source, module);
        }
      }
    },

    getTaxRates(source, module, isTaxExclusive) {
      let sourcePath = "";
      if (!Array.isArray(source)) {
        sourcePath = source;
      } else {
        sourcePath = source[0];
      }
      
      this.$store
        .dispatch("taxRates/getTaxRatesSelect", {
          url: `/${app}/${tenant}/resources/${sourcePath}${module ? `?module=${module}` : ""
            }`,
          taxExclusive: isTaxExclusive,
        })
        .then((response) => {
          this.sourceOptions = response;
          
          setTimeout(() => {
            this.alerts.loading = false;
          }, 500)
        })
        .catch((error) => {
          console.log('tax rates resource error', error)
        });
    },

    getAccounts(source, module, isTaxExclusive, base) {
      let sourcePath = "";
      let storeMethod = "";
      let sourceTypes = [];
      let sourceTypesCodes = [];
      
      if (!Array.isArray(source)) {
        sourcePath = source;
        storeMethod = "accounts/getResource";
      } else {
        sourcePath = source[0];
        storeMethod = "accounts/getResourceBy";
        let sourceTypeKey = source[1];

        sourceTypes = source[2].map((type) => {
          return { [sourceTypeKey]: type }
        });
        
        if (typeof source[3] !== 'undefined') {
          sourceTypesCodes = source[3].map((type) => {
            return { [sourceTypeKey]: type }
          })
        }
      }
      
      let result = [];
      
      /** Get by account codes */
      if (source[1] === 'account_codes') {
        result = this.getByAccountCodes(sourceTypes);
      }

      /** Get by account type codes */
      if (source[1] === 'account_type_codes') {
        result = this.getByAccountTypeCodes(sourceTypes);
      }
      
      /** Get by account type codes */
      if (source[1] === 'account_codes_not') {
        result = this.getByAccountCodesNot(sourceTypes);
      }

      /** Get by account group name */
      if (source[1] === 'account_groups') {
        result = this.getByAccountGroup(sourceTypes);
      }

      /** 
       * Get by account type and codes
       * This should be in specified order
       * */
      if (source[1] === 'account_type_and_codes') {
        let typeCodes = sourceTypes.map((value) => {
          return { ['account_type_codes']: value.account_type_and_codes }
        });

        let codes = sourceTypesCodes.map((value, key) => {
          return { ['account_codes']: value.account_type_and_codes }
        });
        
        result = this.getByAccountTypeAndCodes(typeCodes, codes);
      }
      
      /** Resolve response */
      const accounts = _.sortBy(_.flatten(result), [function(account) { return account.id; }])
        .map((res) => {
          return {
            id: res.id,
            value: `${res.name}`
          };
        });
      
      this.sourceOptions = accounts;
      
      if (typeof base !== 'undefined') {
        this.resourceBase = base;
      }

      /**
       * @info To Do
       */
      // let cacheKey = `${this.resourceBase}_accounts_resource_cache`;
      // const cachedData = localStorage.getItem(cacheKey);
      
      // if (cachedData !== '[]' && cachedData !== '' && cachedData !== null) {
      //   this.sourceOptions = JSON.parse(cachedData);

      //   setTimeout(() => {
      //     this.alerts.loading = false;
      //   }, 500)
      // } else {
        
      // }
    },

    getWithholdingRates(source, module) {
      let sourcePath = "";
      if (!Array.isArray(source)) {
        sourcePath = source;
      } else {
        sourcePath = source[0];
      }
      
      let cacheKey = 'withholding_tax_rates_resource_cache';
      const cachedData = localStorage.getItem(cacheKey);
      
      if (cachedData !== '[]' && cachedData !== '' && cachedData !== null) {
        this.sourceOptions = JSON.parse(cachedData);

        setTimeout(() => {
          this.alerts.loading = false;
        }, 500)
      } else {
        this.$store
          .dispatch(
            "withholding/getResource",
            `/${app}/${tenant}/resources/${sourcePath}`
          )
          .then(async (response) => {
            localStorage.setItem('withholdingRateList', JSON.stringify(response.data));
            
            const withholdingRates = response.data.map((res) => {
              return {
                id: res.id,
                value: `${res.atc_code} - ${res.tax_rate}%`,
              };
            });

            localStorage.setItem(cacheKey, JSON.stringify(withholdingRates));

            this.sourceOptions = withholdingRates;
          })
          .catch((error) => {
            console.log('withholding rates resource error', error)
          });
      }
    },

    getCashBankAccounts(source, module) {
      let sourcePath = '';
      let storeMethod = '';

      if (!Array.isArray(source)) {
        sourcePath = source;
      } else {
        sourcePath = source[0];
      }
      
      if (sourcePath === 'bank_accounts') {
        storeMethod = 'banking/getBankAccounts';
      }

      if (sourcePath === 'cash_accounts') {
        storeMethod = 'cash/getCashAccounts';
      }
      
      // let cacheKey = `${sourcePath}_resource_cache`;
      // const cachedData = localStorage.getItem(cacheKey);
      
      // if (cachedData !== '[]' && cachedData !== '' && cachedData !== null) {
      //   this.sourceOptions = JSON.parse(cachedData);

      //   setTimeout(() => {
      //     this.alerts.loading = false;
      //   }, 500)
      // } else {
        
      // }

      this.$store
        .dispatch(storeMethod, {
          url: `/${app}/${tenant}/resources/${sourcePath}`
        })
        .then((response) => {
          let bankingAccounts = [];
          
          if (sourcePath === 'bank_accounts') {
            bankingAccounts = response.map((res) => {
              return {
                id: res.id,
                value: `${res.bank_account_name} - ${res.bank_account_number}`,
              };
            });
            
            let selectedFrom = this.$attrs.selectedFrom;
            
            if (typeof selectedFrom !== 'undefined') {
              bankingAccounts = _.filter(bankingAccounts, function(bankAccount) { 
                return bankAccount.id !== selectedFrom; 
              });
            }
          }
          
          if (sourcePath === 'cash_accounts') {
            bankingAccounts = response.map((res) => {
              return {
                id: res.id,
                value: `${res.cash_account_name} - ${res.employee.business_display_name}`,
              };
            });
          }
          
          // localStorage.setItem(cacheKey, JSON.stringify(bankingAccounts));

          this.sourceOptions = bankingAccounts;
        })
        .catch((error) => {
          console.log('cash/bank resource error', error);
        });
    },

    /**
     * @info Account resource helpers
     * @param {*} sourceTypes 
     * @returns 
     */
    getByAccountCodes(sourceTypes) {
      const accountsData = this.$page.props.sharedResources.accounts;
      const types = sourceTypes.map(type => type.account_codes);
      return _.filter(accountsData, (account) => types.includes(account.account_code));
    },

    getByAccountTypeCodes(sourceTypes) {
      const accountByType = this.$page.props.sharedResources.accountsByType;
      return sourceTypes.map((type) => {
        return accountByType[type.account_type_codes][0].accounts;
      });
    },

    getByAccountCodesNot(sourceTypes) {
      const accountsNotData = this.$page.props.sharedResources.accounts
      const types = sourceTypes.map(type => type.account_codes_not );
      return _.reject(accountsNotData, (account) => types.includes(account.account_code));
    },

    getByAccountTypeAndCodes(typeCodes, codes) {
      let byTypeCodes = this.getByAccountTypeCodes(typeCodes);
      let byCodes = this.getByAccountCodes(codes);
      
      return _.flattenDeep(byTypeCodes).concat(byCodes);
    },

    getByAccountGroup(sourceTypes) {
      const accountByGroup = this.$page.props.sharedResources.accountsByGroup;
      
      return sourceTypes.map((group) => {
        return accountByGroup[group.account_groups];
      });
    },
  },
  
  mounted() {
    try {
      this.getResource(
        this.source,
        this.$attrs.module,
        this.$attrs.taxExclusive
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
          console.log("Unauthorized. Reloading data fetch...");
          this.getResource(
            this.source,
            this.$attrs.module,
            this.$attrs.taxExclusive
          );
      } else {
          console.error('Caught error message: ', error);
      }
    }
  },
};
