import { mapState } from 'vuex';
import selectResources from '@/mixins/selectResources';
import alerts from '@/mixins/alerts';

export default {
  mixins: [selectResources, alerts],

  data () {
    return {
      // accountOptions: [],
      usedAccounts: []
    };
  },

  computed: {
    ...mapState(['payroll', 'taxRates', 'alerts']),

    recordedDate: {
      get () {
        return this.payroll.data?.parent.recorded_date;
      },
      set (value) {
        this.payroll.data.parent.recorded_date =
          moment(value).format('YYYY-MM-DD');
      }
    },

    payrollPeriod: {
      get () {
        const start = this.payroll.data?.parent.start_date;
        const end = this.payroll.data?.parent.end_date;
        return [start, end];
      },
      set (value) {
        const start = moment(value[0]).format('YYYY-MM-DD');
        const end = moment(value[1]).format('YYYY-MM-DD');

        this.payroll.data.parent.start_date = start;
        this.payroll.data.parent.end_date = end;
      }
    },

    payrollReference() {
      return this.payroll.data?.parent.reference;
    },

    totalDebit() {
      return this.payroll.data?.parent.total_debit;
    },

    totalCredit() {
      return this.payroll.data?.parent.total_credit;
    },
  },

  methods: {
    changeAccount (index, name, value) {
      this.payroll.data.lines[index][name] = value;
      
      this.payroll.data.lines.map((line, i) => {
        if (index !== i) {
          this.payroll.data.lines[i].accountOptions = _.reject(
            line.accountOptions,
            account => account.id === value
          );
        }
      });

      this.usedAccounts = this.usedAccounts.concat(value);
    },

    debitAmount (index, name, value) {
      this.payroll.data.lines[index].debit = value;
      this.$store.dispatch('payroll/setGrandTotals', 'debit');
    },

    creditAmount (index, name, value) {
      this.payroll.data.lines[index].credit = value;
      this.$store.dispatch('payroll/setGrandTotals', 'credit');
    },
  },

  mounted () {
    //
  }
};
