import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  computed: {
    ...mapState(['replenish', 'taxRates', 'alerts']),

    contactState: {
      get() {
        return this.replenish.data.parent.contact_id;
      }
    },

    dateState: {
      get() {
        return this.replenish.data.parent[this.name];
      },
      set(value) {
        this.replenish.data.parent[this.name] = moment(value).format('YYYY-MM-DD');
      }
    },

    referenceState: {
      get() {
        return this.replenish.data.parent.reference;
      },
      set(value) {
        this.replenish.data.parent.reference = value;
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.replenish.data.parent.is_tax_exclusive
      },
      set(value) {
        this.replenish.data.parent.is_tax_exclusive = value
        
        this.replenish.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.replenish.data.lines[index].tax_rate_id = '4'
          } else {
            this.replenish.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value;
        this.$store.dispatch('replenish/setTaxAmounts');
        this.$store.dispatch('replenish/setTotals');
        this.setGrandTotals();
      }
    },
  },

  methods: {
    changeContactSelect(index, name, value) {
      this.replenish.data.parent.contact_id = value
      this.clearParentInvalids()
      this.clearLineInvalids()
      this.alerts.invalid = false;
    },

    changeDynamicTableField(index, name, value) {
      if(
        ![
          'account_id',
          'tax_rate_id',
          'withholding_tax_rate_id',
        ].includes(name)
      ) {
        this.clearLineInvalids();

        if (
          [
            'unit_price',
            'sub_total',
            'tax_amount',
            'total_amount'
          ].includes(name)
        ) {
          /** Clean currency value */
          value = value?.replace(/,/g, '');
        }
        
        this.replenish.data.lines[index][name] = value;
        this.$store.dispatch('replenish/setSubTotals')
        this.$store.dispatch('replenish/setTaxAmounts')
        this.$store.dispatch('replenish/setTotals')
        this.setGrandTotals()
      }
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids()
      this.replenish.data.lines[index][name] = value;
      this.$store.dispatch('replenish/setTaxAmounts')
      this.$store.dispatch('replenish/setTotals')
      
      if(['tax_rate_id', 'withholding_tax_rate_id'].includes(name)) {
        this.setGrandTotals();
      }
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.replenish.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids() {
      const lines = this.replenish.data.lines.map((line, index) => {
        delete line['invalid'];
        return line
      })

      this.replenish.data.lines = lines;
      this.alerts.invalid = false;
    },

    clearParentInvalids() {
      this.replenish.invalidParents = []
      this.alerts.invalid = false;
    },

    setNewLine() {
      this.$store.dispatch('replenish/setNewLine', {tab: this.tab, data: this.data})
    },

    setGrandTotals() {
      // On load, get default tax rates
      this.$store.dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.replenish.data.parent.is_tax_exclusive
        })
        .then(() => {
          this.$store.dispatch('replenish/setGrandTotals');
        })
        .catch(error => { console.log(error) })
    },
  },
}