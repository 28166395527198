export default {
    namespaced: true,
  
    state: {
      loading: false,
      saving: false,
      submitting: false,
      invalid: false,
      unauthorized: false,
      overpayment: false,
      submitted: false,
      fetching: false,
      createDraft: false,
      processing: false,
      processed: false,
      customMessageError: [false, ''],
      customMessage: [false, ''],
    },
}