import bankingDataHandler from "./bankingDataHandler";
import salesCreditDataHandler from "./salesCreditDataHandler";
import billsToPayDataHandler from "./billsToPayDataHandler";
import advancePaymentsHandler from "./advancePaymentsHandler";
import liquidationDataHandler from "./liquidationDataHandler";
import pettyCashDataHandler from "./pettyCashDataHandler";
import spendCashDataHandler from "./spendCashDataHandler";
import replenishDataHandler from "./replenishDataHandler";
import loansDataHandler from "./loansDataHandler";
import loanPaymentsDataHandler from "./loanPaymentsDataHandler";
import payrollDataHandler from "./payrollDataHandler";
import govPayableDataHandler from "./govPayableDataHandler";
import productsCostDataHandler from "./productsCostDataHandler";

export default {
  getDynamicMixins() {
    /**
     * @info Setup mixins per module
     * */
    let module = JSON.parse(app.dataset.page).props.module;
    let mixin = '';
    
    if (module === 'sales') {
        mixin = salesCreditDataHandler;
    }

    if (module === 'banking') {
       mixin = bankingDataHandler;
    }
    
    if (module === 'purchases') {
      if (typeof JSON.parse(app.dataset.page).props.tab !== 'undefined') {
        if (JSON.parse(app.dataset.page).props.tab === 'advance-payments') {
          if (JSON.parse(app.dataset.page).props.type === 'liquidation') {
            mixin = liquidationDataHandler;
          } else {
            mixin = advancePaymentsHandler;
          }
        } else if(JSON.parse(app.dataset.page).props.tab === 'petty-cash') {
          if (JSON.parse(app.dataset.page).props.type === 'petty-cash') {
            mixin = pettyCashDataHandler;
          } else if (JSON.parse(app.dataset.page).props.type === 'spend-cash') {
            mixin = spendCashDataHandler;
          } else if (JSON.parse(app.dataset.page).props.type === 'replenish') {
            mixin = replenishDataHandler;
          }
        } else {
          mixin = billsToPayDataHandler;
        }
      }
    }
    
    if (module === 'loans') {
      if (typeof JSON.parse(app.dataset.page).props.tab !== 'undefined') {
        if(['promissory', 'trust-receipts', 'mortgage', 'other-loans', 'loan-expense'].includes(JSON.parse(app.dataset.page).props.tab)) {
          if (['promissory-payments', 'trust-receipts-payments', 'mortgage-payments', 'other-loans-payments', 'loan-expense-payments'].includes(JSON.parse(app.dataset.page).props.type)) {
            mixin = loanPaymentsDataHandler;
          } else {
            mixin = loansDataHandler;
          }
        }
      }
    }
    
    if (module === 'payroll') {
      if (typeof JSON.parse(app.dataset.page).props.tab !== 'undefined') {
        mixin = payrollDataHandler;
      }
    }

    if (module === 'government') {
      if (typeof JSON.parse(app.dataset.page).props.tab !== 'undefined') {
        mixin = govPayableDataHandler;
      }
    }

    if (module === 'products') {
      if (typeof JSON.parse(app.dataset.page).props.tab !== 'undefined') {
        mixin = productsCostDataHandler;
      }
    }

    return mixin;
  }
}