const eventBus = {};

eventBus.listeners = {};

eventBus.on = function(event, callback) {
  if (!eventBus.listeners[event]) {
    eventBus.listeners[event] = [];
  }
  eventBus.listeners[event].push(callback);
};

eventBus.emit = function(event, ...args) {
  if (eventBus.listeners[event]) {
    eventBus.listeners[event].forEach(callback => {
      callback(...args);
    });
  }
};

eventBus.off = function(event, callback) {
  if (eventBus.listeners[event]) {
    eventBus.listeners[event] = eventBus.listeners[event].filter(
      cb => cb !== callback
    );
  }
};

export default eventBus;