import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { processors } from '../../../functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    tab: '',
    page: {
      list: 'invoice',
      form: false,
      autosave: false,
    },
    data: {
      parent: {},
      lines: [],
    },
    parentValidations: [],
    lineValidations: [],
  },

  mutations: {
    setLine(state, { line }) {
      state.data.lines.push(line);
    },

    removeLine(state, { index }) {
      state.data.lines.splice(index, 1);
    }
  },

  actions: {
    async getParentColumns ({ commit, dispatch, state }, url) {
      return await new Promise((resolve, reject) => {
        axios.get(url, { params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] } })
          .then(async (response) => {
            resolve(response.data)
          })
          .catch(function (error) {
            if (error.response !== 200) {
              window.location.reload()
            }
            console.log(error.response);
            reject();
          });
      });
    },

    async getLinesColumns ({ commit, dispatch, state }) {
      return await new Promise((resolve, reject) => {
        axios.get(`/${app}/${tenant}/states/sales_lines`, { 
            params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] } 
          }).then(async (response) => {
            resolve(response.data)
          })
          .catch(function (error) {
            if (error.response !== 200) {
              window.location.reload()
            }
            console.log(error.response);
            reject();
          });
      });
    },
    
    async setNewLine({ commit, state }, param) {
      const response = await axiosRequest.post(
        `/${app}/${tenant}/sales-lines/${param.tab}/store?isTable=${true}&parentId=${state.data.parent.id}`);

      if (response.message === 'success') {
        const lastIndex = response.sale.lines.length - 1;
        const newlyAddedLine = response.sale.lines[lastIndex];
        const line = JSON.parse(JSON.stringify(newlyAddedLine, (key, value) => value === null ? "" : value));
        commit('setLine', { line });
      }
    },

    setLineColumns ({ dispatch, rootState }, url) {
      return new Promise((resolve, reject) => {
        axios.post(url)
          .then(async (response) => {
            dispatch('setDataToState', response.data.sale)
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
      });
    },

    setLineColumnsOnly({ commit }, line) {
      commit('setLine', { line });
    },

    setLineNoTax({ state }, index) {
      setTimeout(() => {
        state.data.lines[index].tax_rate_id = '4';
      }, 500);
    },

    removeLineColumnsOnly({ commit }, index) {
      commit('removeLine', { index });
    },

    deleteLineColumns ({ dispatch, rootState }, url) {
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(async (response) => {
            dispatch('setDataToState', response.data.sale)
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject();
          });
      });
    },

    setDataToState({ commit, dispatch, state, rootState }, data) {
      const parent = _.omit(data, 'lines');

      setTimeout(() => {
        state.data.parent = parent
      }, 1000);

      const lines = data.lines;
      
      if (lines.length > 0) {
        state.data.lines = lines
      }
      
      state.page.list = parent.transaction_type
      state.page.form = true

      dispatch('setGrandTotals');
    },
    
    createDraft ({ commit, dispatch, state, rootState }) {
      state.data.parent.transaction_type = state.page.list
      state.data.parent.is_tax_exclusive = true
      state.data.parent.status = 'Draft'
      
      axios.interceptors.request.use((config) => {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
          },
          html: '<small>Creating Draft...</small>',
          allowOutsideClick: false,
          showCloseButton: true,
        })

        return config
      });

      dispatch('store', {type: 'credit-sales'}).then((res) => {
        rootState.alerts.createDraft = false;
        window.location.href = `/${app}/${tenant}/sales/credit-sales/${res.id}`
      })
    },

    async createAnotherDraft ({ commit, dispatch, state, rootState }) {
      axios.interceptors.request.use((config) => {
        rootState.alerts.createDraft = true;
        return config;
      })

      let newParent = await dispatch('getParentColumns', `/${app}/${tenant}/states/sales`);
      let newLine = await dispatch('getLinesColumns', `/${app}/${tenant}/states/sales_lines`);

      state.data.parent = {};
      state.data.parent = newParent
      state.data.parent.transaction_type = state.page.list
      state.data.parent.is_tax_exclusive = true
      state.data.parent.status = 'Draft'
      
      state.data.lines = [];
      state.data.lines = [newLine];
      
      dispatch('store').then((res) => {
        window.location.href = `/${app}/${tenant}/sales/credit-sales/${res.id}`
      });
    },

    autoSaveInputs({ commit, dispatch, state, rootState }, params) {
      axios.interceptors.request.use((config) => {
        rootState.alerts.saving = true
        return config
      })
      
      dispatch('update', params)
    },

    store({ state, rootState }, param) {
      let url = `/${app}/${tenant}/sales/credit-sales/store`;

      if (param.type === 'products-used') {
        url = `/${app}/${tenant}/sales/credit-sales/${param.parentId}/products-used`;
      }
      
      return axios
        .post(url, {
          parent: state.data.parent,
          lines: state.data.lines,
        })
        .then((response) => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);
          
          if (response.data.message === 'invalid') {
            const validation = processors.getTabledFormValidation(response);
            state.parentValidations = validation.parent;
            state.lineValidations = validation.lines;

            rootState.alerts.saving = false;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 500);

            return response.data;
          }
          
          return response.data.sale
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    update({ state, rootState }, params) {
      let extendedUrl = `/update`;

      if (params.productUsedId) {
        extendedUrl = `/products-used/${params.productUsedId}`;
      }

      return axios.put(
        `/${app}/${tenant}/sales/credit-sales/${state.data.parent.id}${extendedUrl}?action=true&status=${params.status}&previous=${params.previousStatus}`, 
        {
          parent: state.data.parent,
          lines: state.data.lines,
        })
        .then((response) => {
          const myTimeout = setTimeout(function () {
            rootState.alerts.saving = false
          }, 500);
          
          if (response.data.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.data.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;
            
            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.data.lineErrors;
            let validatedRows = processors.validateRows(lineErrors);
            state.lineValidations = validatedRows;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.saving = false
            return response.data
          }

          rootState.alerts.saving = false
          return response.data
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },

    delete({}, params) {
      let extendedUrl = '';

      if (params.hasOwnProperty('subId')) {
        extendedUrl = `/products-used/${params.subId}`;
      }

      return axiosRequest.delete(
        `/${app}/${tenant}/sales/credit-sales/${params.id}${extendedUrl}`
      );
    },

    setSubTotals({ commit, dispatch, state }) {
      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0
        const subTotal = unitPrice * quantity
        
        state.data.lines[index]['sub_total'] = parseFloat(subTotal).toFixed(2)
      })
    },

    setTaxAmounts({ commit, dispatch, state, rootState }) {
      const cachedWithholding = localStorage.getItem('withholdingRateList');
      let rateList = rootState.taxRates.rates
      let withholdingRateList = rootState.withholding.withholdingRates;
      
      if (cachedWithholding !== '[]' && cachedWithholding !== '' && cachedWithholding !== null) {
        withholdingRateList = JSON.parse(cachedWithholding);
      }

      let lines = state.data.lines;
      lines.map((line, index) => {
        const quantity = lines[index].quantity;
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0;
        const subTotal = unitPrice * quantity;
        const taxRate = lines[index].tax_rate_id;
        const prepaidTaxRate = lines[index].prepaid_tax_id;
        const vatWithheldTaxRate = lines[index].vat_withheld_id;
        
        if (taxRate) {
          const percentage = _.find(Object.values(rateList), { id: parseInt(taxRate) }).percentage / 100;
          const taxAmount = subTotal * percentage;
          state.data.lines[index]['tax_amount'] = parseFloat(taxAmount).toFixed(2);
        }
        
        if (prepaidTaxRate) {
          const prepaidPercentage = _.find(Object.values(withholdingRateList), { id: parseInt(prepaidTaxRate) })?.tax_rate / 100;
          const prepaidAmount = subTotal * prepaidPercentage;
          state.data.lines[index]['prepaid_tax_amount'] = parseFloat(prepaidAmount).toFixed(2);
        } else {
          state.data.lines[index]['prepaid_tax_amount'] = parseFloat(0).toFixed(2)
        }

        if (vatWithheldTaxRate) {
          const vatWithheldPercentage = _.find(Object.values(withholdingRateList), { id: parseInt(vatWithheldTaxRate) })?.tax_rate / 100;
          const vatWithheldAmount = subTotal * vatWithheldPercentage;
          state.data.lines[index]['vat_withheld_amount'] = parseFloat(vatWithheldAmount).toFixed(2);
        } else {
          state.data.lines[index]['vat_withheld_amount'] = parseFloat(0).toFixed(2)
        }
      })
    },

    setTotals({ commit, dispatch, state }) {
      let lines = state.data.lines;
      
      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        const taxAmount = lines[index].tax_amount

        const totalAmount = parseFloat(subTotal) + parseFloat(taxAmount || 0);

        state.data.lines[index]['total_amount'] = parseFloat(totalAmount).toFixed(2)
      })
    },

    setGrandTotals({ commit, dispatch, state, rootState }) {
      let cachedWithholding = localStorage.getItem('withholdingRateList');
      let rateList = rootState.taxRates.rates
      let withholdingRateList = rootState.withholding.withholdingRates
      let lines = state.data.lines;
      
      if (cachedWithholding !== '[]' && cachedWithholding !== '' && cachedWithholding !== null) {
        withholdingRateList = JSON.parse(cachedWithholding);
      }
      
      let all_sub_total = [];
      let all_vatable = [];
      let all_vat_exempt = [];
      let all_zero_rated = [];
      let all_no_tax = [];
      let all_vat = [];
      let all_prepaid = [];
      let all_vat_withheld = [];

      lines.map((line, index) => {
        const quantity = lines[index].quantity
        const unitPrice = lines[index].unit_price?.replace(/,/g, '') ?? 0
        const subTotal = unitPrice * quantity
        const taxRate = lines[index].tax_rate_id
        const prepaidRate = lines[index].prepaid_tax_id
        const vatWithheldRate = lines[index].vat_withheld_id

        if (taxRate) {
          // Get all row subtotals
          all_sub_total.push(subTotal);

          // Get vatables
          const vatable = _.find(rateList, { id: parseInt(taxRate), code: 'vatable' });
          if (vatable) {
            all_vatable.push(subTotal);
          }

          // Get vat exempt
          const vatExempt = _.find(rateList, { id: parseInt(taxRate), code: 'vat_exempt' });
          if (vatExempt) {
            all_vat_exempt.push(subTotal);
          }

          // Get zero rated
          const zeroRated = _.find(rateList, { id: parseInt(taxRate), code: 'zero_rated' });
          if (zeroRated) {
            all_zero_rated.push(subTotal);
          }

          // Get no tax
          const noTax = _.find(rateList, { id: parseInt(taxRate), code: 'no_tax' });
          if (noTax) {
            all_no_tax.push(subTotal);
          }
          
          // Get vat
          const taxSelected = _.find(rateList, { id: parseInt(taxRate) });
          if (taxSelected) {
            const taxAmount = subTotal * (taxSelected.percentage / 100)
            all_vat.push(taxAmount);
          }
        }

        if (prepaidRate) {
          const prepaidSelected = _.find(withholdingRateList, { id: parseInt(prepaidRate) });
          const prepaidAmount = subTotal * (prepaidSelected.tax_rate / 100)
          all_prepaid.push(prepaidAmount);
        }

        if (vatWithheldRate) {
          const vatWithheldSelected = _.find(withholdingRateList, { id: parseInt(vatWithheldRate) });
          const vatWithheldAmount = subTotal * (vatWithheldSelected.tax_rate / 100)
          all_vat_withheld.push(vatWithheldAmount);
        }
      })

      const totalSubTotals = all_sub_total.reduce((sum, value) => sum + value, 0);
      const totalVatables = all_vatable.reduce((sum, value) => sum + value, 0);
      const totalVatExempt = all_vat_exempt.reduce((sum, value) => sum + value, 0);
      const totalZeroRated = all_zero_rated.reduce((sum, value) => sum + value, 0);
      const totalNoTax = all_no_tax.reduce((sum, value) => sum + value, 0);
      const totalVat = all_vat.reduce((sum, value) => sum + value, 0);
      const totalGross = totalVatables + totalVatExempt + totalZeroRated + totalNoTax + totalVat
      const totalPrepaid = all_prepaid.reduce((sum, value) => sum + value, 0);
      const totalVatWithheld = all_vat_withheld.reduce((sum, value) => sum + value, 0);

      state.data.parent.sub_total = parseFloat(totalSubTotals).toFixed(2);
      state.data.parent.total_vatable = parseFloat(totalVatables).toFixed(2);
      state.data.parent.total_vat_exempt = parseFloat(totalVatExempt).toFixed(2);
      state.data.parent.total_zero_rated = parseFloat(totalZeroRated).toFixed(2);
      state.data.parent.total_no_tax = parseFloat(totalNoTax).toFixed(2);
      state.data.parent.total_tax = parseFloat(totalVat).toFixed(2);
      state.data.parent.total_prepaid_tax = parseFloat(totalPrepaid).toFixed(2);
      state.data.parent.total_vat_withheld = parseFloat(totalVatWithheld).toFixed(2);
      state.data.parent.grand_total = parseFloat(totalGross).toFixed(2);
    }
  },
};