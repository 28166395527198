// require('./bootstrap');
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/core/jquery-v3.3.1/jquery.min.js';
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { createStore } from 'vuex'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { InertiaProgress } from '@inertiajs/progress'
import { ZiggyVue } from 'ziggy-js';
import { Ziggy } from './ziggy.js';

/** Custom Functions */
import { autoFunctionLoader } from './functions/autoFunctionLoader.js';
import mixinService from "@/mixins/mixinService";
import { translations } from './functions/translations.js';
import { formatter } from './functions/formatter.js';
import { processors } from './functions/processors.js';
import { pathResolver } from './functions/pathResolver.js';
import { inertiaXHR } from './functions/utils/inertiaXHR.js';
import { customProgressBar } from './functions/utils/custom-progress-bar.js';
import axiosRequest from './functions/axiosRequests.js';

/** Plugins */
import { dataTables, dataTableQuery } from './plugins/dataTables.js';

/** JS files */
import constants from '@/constants.js';
import eventBus from '@/eventBus.js';

/** Components */
import LoadingComponent from '@/Pages/Components/LoadingComponent.vue'
import CustomLink from '@/Pages/Components/CustomLink.vue'
import Buttons from '@/Pages/Components/Buttons.vue'
import HeaderEditButton from '@/Pages/Components/FormButtons/HeaderEditButton.vue'
import HeaderDeleteButton from '@/Pages/Components/FormButtons/HeaderDeleteButton.vue'
import HeaderVoidButton from '@/Pages/Components/FormButtons/HeaderVoidButton.vue'

/** Custom Components */
// import AccountsField from '@/Pages/Inbooks/Contents/Forms/Components/AccountsField.vue'

/** Vuex modules */
import alerts from './store/Module/alerts'
import taxRates from './store/Module/taxRates'
import accounts from './store/Module/accounts'
import withholding from './store/Module/withholding'
import contacts from './store/Module/contacts'
import salesCredits from './store/Module/Sales/salesCredits'
import salesCollections from './store/Module/Sales/salesCollections'
import banking from './store/Module/CashBanking/banking'
import cash from './store/Module/CashBanking/cash'
import billsToPay from './store/Module/Purchases/billsToPay'
import advancePayments from './store/Module/Purchases/advancePayments'
import liquidation from './store/Module/Purchases/liquidation'
import pettyCash from './store/Module/Purchases/pettyCash'
import spendCash from './store/Module/Purchases/spendCash'
import replenish from './store/Module/Purchases/replenish'
import billsPayments from './store/Module/Purchases/billsPayments'
import loans from './store/Module/Loans/loans'
import loanPayments from './store/Module/Loans/loanPayments'
import projects from './store/Module/projects'
import products from './store/Module/products'
import payroll from './store/Module/Payroll/payroll'
import govPayments from './store/Module/Government/govPayments'
import profile from './store/Module/profile'
import taxSettings from './store/Module/BusinessSettings/taxSettings'

/** 3rd party libraries */
import 'animate.css';
import axios from "axios";
import Swal from 'sweetalert2'
import moment from 'moment';
import lodash from 'lodash'
import mitt from 'mitt'

// Set CSRF token
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

window.Swal = Swal
window.axios = axios
window.moment = moment
window._ = lodash
window.jQuery = $;
window.$ = $;

// InertiaProgress.init();
const emitter = mitt();

function registerComponents(app, components) {
  components.forEach(component => {
    app.component(component.name, component);
  });
}

createInertiaApp({
  resolve: name => import(`./Pages/${name}.vue`),
  resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  async setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(store)
      .use(ZiggyVue, await Ziggy.getRoutes())
      .use(autoFunctionLoader)
      .mixin(mixinService.getDynamicMixins())
      
      registerComponents(app, [
        LoadingComponent,
        CustomLink,
        HeaderEditButton,
        HeaderDeleteButton,
        HeaderVoidButton,
        Buttons,
        // AccountsField,
      ]);

    //Functions, plugins and packages
    app.config.globalProperties.autoFunctionLoader = autoFunctionLoader;
    app.config.globalProperties.customProgressBar = customProgressBar;
    app.config.globalProperties.inertiaXHR = inertiaXHR;
    app.config.globalProperties.axiosRequest = axiosRequest;
    app.config.globalProperties.emitter = emitter
    app.config.globalProperties.$translations = translations;
    app.config.globalProperties.dataTables = dataTables;
    app.config.globalProperties.dataTableQuery = dataTableQuery;
    app.config.globalProperties.constants = constants;
    app.config.globalProperties.eventBus = eventBus;
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.formatter = formatter;
    app.config.globalProperties.pathResolver = pathResolver;
    app.config.globalProperties.translate = processors.translate;
    app.config.globalProperties.validate = processors.validate;
    app.config.globalProperties.$lodash = lodash;
    
    app.mount(el);
  },
});

const store = createStore({
  modules: {
    alerts,
    salesCredits,
    salesCollections,
    accounts,
    taxRates,
    withholding,
    contacts,
    banking,
    cash,
    billsToPay,
    advancePayments,
    liquidation,
    billsPayments,
    pettyCash,
    spendCash,
    replenish,
    loans,
    loanPayments,
    projects,
    products,
    payroll,
    govPayments,
    profile,
    taxSettings,
  }
});

// InertiaProgress.init