import { pathResolver } from '@/functions/pathResolver.js';
import axiosRequest from '@/functions/axiosRequests.js';
import { processors } from '@/functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    // tab: '',
    data: {
      business_id: '',
      business_location_id: '',
      tax: {
        currency: '',
        registered_name: '',
        trade_name: '',
        tax_identification_number: '',
        rdo_code: '',
        rdo_name: '',
      },
      address: {
        substreet: '',
        street: '',
        barangay: '',
        district_municipality: '',
        city_province: '',
        zip_code: '',
      },
      representative: {
        tax_rep_first_name: '',
        tax_rep_middle_name: '',
        tax_rep_last_name: '',
        tax_rep_designation: '',
        tax_rep_tin: '',
      },
    },
    validations: {
      registered_name: false,
      tax_identification_number: false,
      rdo_code: false,
      street: false,
      barangay: false,
      district_municipality: false,
      city_province: false,
      zip_code: false,
      tax_rep_first_name: false,
      tax_rep_last_name: false,
      tax_rep_designation: false,
      tax_rep_tin: false,
    },
  },

  mutations: {
    setTax(state, data) {
      state.data.tax = data;
    },

    setAddress(state, data) {
      state.data.address = data;
    },

    setRep(state, data) {
      state.data.representative = data;
    },
  },

  actions: {
    setTax({ commit, state }, businessInfo) {
      state.data.business_id = businessInfo.id;
      commit('setTax', {
        currency: businessInfo.currency,
        registered_name: businessInfo.registered_name,
        trade_name: businessInfo.trade_name,
        tax_identification_number: businessInfo.tax_identification_number,
        rdo_code: businessInfo.rdo_code,
        rdo_name: businessInfo.rdo_name,
      });
    },

    setAddress({ commit, state }, businessLocation) {
      state.data.business_location_id = businessLocation.id;
      commit('setAddress', {
        substreet: businessLocation.substreet,
        street: businessLocation.street,
        barangay: businessLocation.barangay,
        district_municipality: businessLocation.district_municipality,
        city_province: businessLocation.city_province,
        zip_code: businessLocation.zip_code,
      });
    },

    setRep({ commit }, businessInfo) {
      commit('setRep', {
        tax_rep_first_name: businessInfo.tax_rep_first_name,
        tax_rep_middle_name: businessInfo.tax_rep_middle_name,
        tax_rep_last_name: businessInfo.tax_rep_last_name,
        tax_rep_designation: businessInfo.tax_rep_designation,
        tax_rep_tin: businessInfo.tax_rep_tin,
      });
    },

    update({state, rootState}) {
      return axiosRequest
        .put(
          `/${app}/${tenant}/business-settings/tax`,
          state.data
        )
        .then((response) => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);
          
          if (response.message === 'invalid') {
            const errors = response.errors;
            state.validations = errors;
            
            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response.message;
          }
          
          return response;
        });
    }
  },
}