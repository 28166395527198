import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { sharedProps } from '@/functions/data/sharedResources';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    list: [],
    withholdingRates: [],
  },

  actions: {
    async getAll({ state }, params) {
      const sharedInfo = sharedProps.get();
      let tenantId = sharedInfo.tenant;

      if (tenantId.hasOwnProperty('id')) {
          tenantId = sharedInfo.tenant?.id;
      }
      return await axiosRequest.get(`/${sharedInfo.app}/${tenantId}/resources/withholding_rates`)
        .then((response) => {
          state.list = response;
        });
    },

    async getResource({ commit, dispatch, state, rootState }, url) {
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then(async (response) => {
          rootState.alerts.saving = false
          state.withholdingRates = response.data
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject();
        });
      });
    },

    async getWithholdingById({ dispatch, state }, id) {
      let withholding = {};
      
      if (state.list.length > 0) {
        withholding = dispatch('getWithholdingFromList', id);
      }

      if (state.list.length === 0) {
        await dispatch('getAll').then(async () => {
          withholding = await dispatch('getWithholdingFromList', id);
        });
      }
      
      return withholding
    },

    getWithholdingFromList ({ state }, id) {
      return _.find(state.list, function(withholding) { return withholding.id === id; })
    }
  }
}