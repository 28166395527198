import { pathResolver } from '@/functions/pathResolver.js';
import axiosRequest from '@/functions/axiosRequests.js';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    // tab: '',
    data: {
      personal: {
        first_name: '',
        middle_name: '',
        last_name: '',
        terms_agreed: false,
      },
      signin: {
        email: '',
        password: '',
      },
    },
  },

  mutations: {
    setPersonal(state, data) {
      state.data.personal = data;
    },

    setSignin(state, data) {
      state.data.signin = data;
    },
  },

  actions: {
    setPersonal({ commit }, userData) {
      commit('setPersonal', {
        first_name: userData.first_name,
        middle_name: userData.middle_name,
        last_name: userData.last_name,
        terms_agreed: userData.terms_agreed,
      });
    },

    setSignin({ commit }, userData) {
      commit('setSignin', {
        email: userData.email,
        password: 'Just a dummy password',
      });
    },

    update({ state }, params) {
      let query = new URLSearchParams();
      query.append('dataSet', params.type);
      
      if (params.user) {
        query.append('user', params.user);
      }
      
      const queryString = `?${query.toString()}`;
      
      return axiosRequest
        .put(
          `/${app}/${tenant}/profile/${params.tab}/update${queryString}`,
          state.data[params.type]
        )
        .then((response) => {
          return response;
        });
    },
  },
};
