import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      //
    }
  },

  computed: {
    ...mapState(['products', 'taxRates', 'alerts']),

    dateState: {
      get() {
        return this.products.data.parent[this.name];
      },
      set(value) {
        this.products.data.parent[this.name] = moment(value).format('YYYY-MM-DD');
      }
    },

    transactionNumber: {
      get() {
        return this.products.data.parent.transaction_number;
      },
      set(value) {
        this.products.data.parent.transaction_number = value;
      }
    },

    referenceState: {
      get() {
        return this.products.data.parent.reference;
      },
      set(value) {
        this.products.data.parent.reference = value;
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.products.data.parent.is_tax_exclusive
      },
      set(value) {
        this.products.data.parent.is_tax_exclusive = value
        
        this.products.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.products.data.lines[index].tax_rate_id = '4'
          } else {
            this.products.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value
        this.$store.dispatch('products/setTaxAmounts')
        this.$store.dispatch('products/setTotals')
        this.setGrandTotals()
      }
    },
  },

  methods: {
    changeDynamicTableField(index, name, value) {
      if(
        ![
          'account_id',
          'tax_rate_id',
          'withholding_tax_rate_id',
        ].includes(name)
      ) {
        this.clearLineInvalids(value, index);

        if (
          [
            'unit_price',
            'sub_total',
            'tax_amount',
            'total_amount'
          ].includes(name)
        ) {
          /** Clean currency value */
          value = value?.replace(/,/g, '');
        }
        
        this.products.data.lines[index][name] = value;
        this.$store.dispatch('products/setSubTotals')
        this.$store.dispatch('products/setTaxAmounts')
        this.$store.dispatch('products/setTotals')
        this.setGrandTotals()
      }
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids(value, index)
      this.products.data.lines[index][name] = value;
      this.$store.dispatch('products/setTaxAmounts')
      this.$store.dispatch('products/setTotals')
      
      if(['tax_rate_id', 'withholding_tax_rate_id'].includes(name)) {
        this.setGrandTotals();
      }
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.products.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids(key, index) {
      if (this.products.lineValidations.length > 0) {
        if (this.products.lineValidations[index].hasOwnProperty(key)) {
          delete this.products.lineValidations[index][key];
          this.alerts.invalid = false;
        }
      }
    },

    clearParentInvalids(key) {
      if (this.products.parentValidations.hasOwnProperty(key)) {
        delete this.products.parentValidations[key];
        this.alerts.invalid = false;
      }
    },

    setGrandTotals() {
      /** @info On load, get default tax rates */
      this.$store.dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.products.data.parent.is_tax_exclusive
        })
        .then(response => {
          this.$store.dispatch('products/setGrandTotals');
        })
        .catch(error => { console.log(error) })
    },
  },
}