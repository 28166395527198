import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { sharedProps } from '../../functions/data/sharedResources';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
    namespaced: true,

    state: {
        tenant: '',
        list: [],
        groups: [],
        types: [],
        accounts: [],
        accountFormData: {},
    },

    mutations: {
      setAccountFormData(state, data) {
        state.accountFormData = data
      }
    },

    actions: {
      setAccountFormData({ commit, state }, data) {
        commit('setAccountFormData', data)
      },

      async store({ state }) {
        return await axiosRequest.post(
          `/admin/tenants/${sharedProps.get().tenant.id}/accounts`,
          {
            action: 'create',
            account: state.accountFormData
          }
        );
      },

      async update({ commit, dispatch, state }) {
        console.log(sharedProps.get())
        return await axiosRequest.put(
          `/admin/tenants/${sharedProps.get().tenant.id}/accounts/${state.accountFormData.id}`,
          {
            action: 'update',
            account: state.accountFormData
          }
        );
      },

      async getAll({ state }) {
        return await axiosRequest.get(`/${app}/${tenant}/resources/accounts`)
          .then((response) => {
            state.groups = response.accountGroups;
            state.taxRates = response.taxRates;
            state.list = response.accounts;
          }).catch((error) => {
            console.log('accounts all error in store module', error)
          });
      },

      async getResource({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
          axios.get(params.url)
          .then(async (response) => {
            state.accounts = response.data;

            if (params.module === 'sales') {
              if (params.isTaxExclusive === 1 || params.isTaxExclusive === '1') {
                const newData = _.filter(response.data, function(data) { 
                  return data.account_code !== 'pass_through'; 
                });
                
                state.accounts = newData
              }
            }
            
            state.rates = response.data
            resolve(response);
          })
          .catch(function (error) {
            console.log('accounts resource error in store module', error)
            reject();
          });
        });
      },

      /* Requires an array of TYPES from dispatch as it will be converted to query strings */
      async getResourceBy({ commit, dispatch, state }, params) {
        const typeParams = new URLSearchParams();
        
        params.types.forEach(obj => {
            Object.entries(obj).forEach(([key, value]) => {
                typeParams.append(`${key}[]`, value);
            });
        });
        
        return new Promise((resolve, reject) => {
          axios.get(`${params.url}${params.types.length ? `&${typeParams.toString()}` : ''}`)
          .then(async (response) => {
            state.accounts = response.data;
            
            if (params.module === 'sales') {
              if (params.isTaxExclusive === 1 || params.isTaxExclusive === '1') {
                const newData = _.filter(response.data, function(data) { 
                  return data.account_code !== 'pass_through'; 
                });
                
                state.accounts = newData
              }
            }
            
            state.rates = response.data
            resolve(response);
          })
          .catch(function (error) {
            console.log('accounts resourceBy error in store module', error)
            reject();
          });
        });
      },
    },
};
