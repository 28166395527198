import axios from "axios";

// const data = axios.get();

// const Ziggy = {"url":"http:\/\/inwebapps_v2.test","port":null,"defaults":{},"routes":{"debugbar.openhandler":{"uri":"_debugbar\/open","methods":["GET","HEAD"]},"debugbar.clockwork":{"uri":"_debugbar\/clockwork\/{id}","methods":["GET","HEAD"],"parameters":["id"]},"debugbar.assets.css":{"uri":"_debugbar\/assets\/stylesheets","methods":["GET","HEAD"]},"debugbar.assets.js":{"uri":"_debugbar\/assets\/javascript","methods":["GET","HEAD"]},"debugbar.cache.delete":{"uri":"_debugbar\/cache\/{key}\/{tags?}","methods":["DELETE"],"parameters":["key","tags"]},"ignition.healthCheck":{"uri":"_ignition\/health-check","methods":["GET","HEAD"]},"ignition.executeSolution":{"uri":"_ignition\/execute-solution","methods":["POST"]},"ignition.updateConfig":{"uri":"_ignition\/update-config","methods":["POST"]},"stancl.tenancy.asset":{"uri":"tenancy\/assets\/{path?}","methods":["GET","HEAD"],"wheres":{"path":"(.*)"},"parameters":["path"]},"address.regions.all":{"uri":"api\/address\/regions","methods":["GET","HEAD"]},"address.provinces.all":{"uri":"api\/address\/provinces","methods":["GET","HEAD"]},"address.provinces.region":{"uri":"api\/address\/provinces\/{regionId}","methods":["GET","HEAD"],"parameters":["regionId"]},"address.cities.province":{"uri":"api\/address\/cities\/{provinceId}","methods":["GET","HEAD"],"parameters":["provinceId"]},"address.cities.region.province":{"uri":"api\/address\/cities\/{regionId}\/{provinceId}","methods":["GET","HEAD"],"parameters":["regionId","provinceId"]},"address.barangay.city":{"uri":"api\/address\/barangays\/{cityId}","methods":["GET","HEAD"],"parameters":["cityId"]},"home":{"uri":"\/","methods":["GET","HEAD"]},"admin":{"uri":"admin","methods":["GET","HEAD"]},"login.create":{"uri":"login","methods":["GET","HEAD"]},"password.reset":{"uri":"reset-password\/{token}","methods":["GET","HEAD"],"parameters":["token"]},"password.update":{"uri":"reset-password","methods":["POST"]}}};

// if (typeof window !== 'undefined' && typeof window.Ziggy !== 'undefined') {
//     Object.assign(Ziggy.routes, window.Ziggy.routes);
// }

// export { Ziggy };

export const Ziggy = {
    async getToken(id) {
      const token = await new Promise(async (resolve, reject) => {
        try {
          const response = await axios.get(`/api/get-access-token/${id}`);
          resolve(response.data.token);
        } catch (error) {
          console.error('Error:', error);
          reject(error);
        }
      });
      
      return token;
    },

    async getRoutes() {
        let token = await this.getToken(JSON.parse(app.dataset.page).props.auth_id);
        
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
        
        const data = new Promise(async (resolve, reject) => {
          try {
            const response = await axios.get('/api/compiled-routes');

            let Ziggy = response.data;
            if (typeof window !== 'undefined' && typeof window.Ziggy !== 'undefined') {
                Object.assign(Ziggy.routes, window.Ziggy.routes);
            }
            
            resolve(Ziggy);
          } catch (error) {
            console.error('Error:', error);
            reject(error);
          }
        });
        
        return await data;
    },
}
