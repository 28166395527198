import { pathResolver } from "@/functions/pathResolver.js";

export async function loadSharedResources() {
  let app = pathResolver.primaryPaths("app");
  let tenant = pathResolver.primaryPaths("tenant");
  
  const resources = axios.get(`/${app}/${tenant}/shared-resources`)
    .then((resource) => {
      return resource.data;
    });

  return await resources;
}

export const sharedProps = {
  get() {
    return JSON.parse(app.dataset.page).props;
  }
}