import { mapState } from 'vuex';
import selectResources from '@/mixins/selectResources';

export default {
  mixins: [selectResources],

  computed: {
    ...mapState(['salesCredits', 'alerts']),

    tabState: {
      get () {
        return this.salesCredits.tab;
      },
      set (value) {
        this.salesCredits.tab = value;
      }
    },

    contactState: {
      get () {
        return this.salesCredits.data.parent.transaction_to;
      }
    },

    chargeAgainstState: {
      get () {
        return this.salesCredits.data.parent.credit_memo_to;
      }
    },

    dateState: {
      get () {
        return this.salesCredits.data.parent[this.name];
      },
      set (value) {
        this.salesCredits.data.parent[this.name] =
          moment(value).format('YYYY-MM-DD');

        if (this.salesCredits.page.autosave) {
          this.autoSave(`parent.${this.name}`);
        }
      }
    },

    projectState: {
      get () {
        return this.salesCredits.data.parent.project_id;
      }
    },

    referenceState: {
      get () {
        return this.salesCredits.data.parent.reference;
      }
    },

    transactionNumber: {
      get () {
        return this.salesCredits.data.parent.invoice_and_statement_number;
      },
      set (value) {
        this.salesCredits.data.parent.invoice_and_statement_number = value;
      }
    },

    productUsedNumber: {
      get () {
        return this.salesCredits.data.parent.product_used_number;
      },
      set (value) {
        this.salesCredits.data.parent.product_used_number = value;
      }
    },

    creditMemoNumber: {
      get () {
        return this.salesCredits.data.parent.credit_memo_number;
      },
      set (value) {
        this.salesCredits.data.parent.credit_memo_number = value;
      }
    },

    isTaxExclusiveState: {
      get () {
        return this.salesCredits.data.parent.is_tax_exclusive;
      },
      set (value) {
        this.salesCredits.data.parent.is_tax_exclusive = value;

        this.salesCredits.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.salesCredits.data.lines[index].tax_rate_id = '4';
          } else {
            this.salesCredits.data.lines[index].tax_rate_id = '1';
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value;
        this.$store.dispatch('salesCredits/setTaxAmounts');
        this.$store.dispatch('salesCredits/setTotals');
        this.setGrandTotals();
      }
    }
  },

  watch: {
    'salesCredits.data.parent.recorded_date' (newVal, oldVal) {
      this.changeTermByDates(
        'recorded_date',
        this.salesCredits.data.parent.due_date,
        newVal
      );
    },

    'salesCredits.data.parent.due_date' (newVal, oldVal) {
      this.changeTermByDates(
        'due_date',
        this.salesCredits.data.parent.recorded_date,
        newVal
      );
    }
  },

  methods: {
    changeContactSelect (index, name, value) {
      this.salesCredits.data.parent[name] = value;
      this.clearParentInvalids();
      this.clearLineInvalids();
      this.alerts.invalid = false;

      if (this.salesCredits.page.autosave) {
        this.autoSave('parent.transaction_to');
      }
    },

    togglableContactChanged (isChecked) {
      this.salesCredits.data.parent.credit_memo_to =
        this.salesCredits.data.parent.transaction_to;

      if (isChecked) {
        this.salesCredits.data.parent.credit_memo_to = '';
      }
    },

    changeProjectSelect (index, name, value) {
      this.salesCredits.data.parent.project_id = value;
    },

    changeTermSelect (index, name, value) {
      const newDueDate = moment(this.salesCredits.data.parent.recorded_date)
        .add(value, 'days')
        .format('D MMM YYYY');

      this.changeDueByTerms(newDueDate);
    },

    changeDueByTerms (newDueDate) {
      this.salesCredits.data.parent.due_date =
        moment(newDueDate).format('YYYY-MM-DD');

      if (this.salesCredits.page.autosave) {
        this.autoSave('parent.due_date');
      }
    },

    changeTermByDates (field, otherField, newValue) {
      const startDate =
        field === 'recorded_date'
          ? moment(newValue)
          : moment(this.salesCredits.data.parent.recorded_date);

      const endDate =
        field === 'due_date'
          ? moment(newValue)
          : moment(this.salesCredits.data.parent.due_date);

      const newTerms = endDate.diff(startDate, 'days');
      this.selectedTerm = newTerms;

      this.salesCredits.data.parent.overdue_by = newTerms;
    },

    changedReferenceInput (e) {
      this.salesCredits.data.parent.reference = e.target.value;

      if (this.salesCredits.page.autosave) {
        this.autoSave('parent.reference');
      }
    },

    changedAssignableInput (e) {
      this.salesCredits.data.parent.is_custom_number = true;
    },

    changeDynamicTableField (index, name, value) {
      if (
        ![
          'account_id',
          'prepaid_tax_id',
          'vat_withheld_id',
          'tax_rate_id'
        ].includes(name)
      ) {
        this.clearLineInvalids();
        
        if (
          ['unit_price', 'sub_total', 'tax_amount', 'total_amount'].includes(
            name
          )
        ) {
          /** Clean currency value */
          value = value?.replace(/,/g, '');
        }

        this.salesCredits.data.lines[index][name] = value;
        this.$store.dispatch('salesCredits/setSubTotals');
        this.$store.dispatch('salesCredits/setTaxAmounts');
        this.$store.dispatch('salesCredits/setTotals');
        this.setGrandTotals(index, name);
      }
    },

    changeDynamicTableSelectField (index, name, value) {
      if (typeof name !== 'undefined') {
        this.clearLineInvalids();
        this.salesCredits.data.lines[index][name] = value;
        this.$store.dispatch('salesCredits/setTaxAmounts');
        this.$store.dispatch('salesCredits/setTotals');

        if (['prepaid_tax_id', 'vat_withheld_id', 'tax_rate_id'].includes(name)) {
          this.setGrandTotals();
        }
      }
    },

    setGrandTotals (index, name) {
      // On load, get default tax rates
      this.$store
        .dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.salesCredits.data.parent.is_tax_exclusive
        })
        .then(response => {
          this.$store.dispatch('salesCredits/setGrandTotals');
        })
        .catch(error => {
          console.log(error);
        });
    },

    isDynamicLineInvalid (index, name) {
      const lineValidations = this.salesCredits.lineValidations;

      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids () {
      const lines = this.salesCredits.data.lines.map((line, index) => {
        delete line['invalid'];
        return line;
      });

      this.salesCredits.data.lines = lines;
      this.alerts.invalid = false;
    },

    clearParentInvalids () {
      this.salesCredits.invalidParents = [];
      this.alerts.invalid = false;
    },

    autoSave (input) {
      this.$store.dispatch('salesCredits/autoSaveInputs', {
        url: `/${this.app}/${this.tenant}/sales/${this.tab}/${this.salesCredits.data.parent.id}/update`,
        input: input
      });
    },

    setAsCreditSales () {
      this.$store
        .dispatch('salesCredits/setDataToState', this.data)
        .then(() => {
          this.$store.state.taxRates.taxExclusive = this.data?.is_tax_exclusive;

          setTimeout(() => {
            if (this.data.project_id === null) {
              this.salesCredits.data.parent.project_id = '0';
            }
          }, 1000);
        });

      this.$store.dispatch(
        'salesCollections/setCollectionList',
        this.data.collections
      );
    },

    setAsProductUsed () {
      this.disabledTax = true;

      if (typeof this.productUsed !== 'undefined') {
        this.$store
          .dispatch('salesCredits/setDataToState', this.productUsed)
          .then(() => {
            this.$store.state.taxRates.taxExclusive =
              this.productUsed?.is_tax_exclusive;

            this.productUsed.lines.map((line, index) => {
              if (line.products) {
                let accountCodes = ['cost_good_sold'];

                if (this.data.transaction_type === 'statement') {
                  accountCodes = ['direct_cost_services'];
                }

                const productableSource = [
                  'accounts',
                  'account_codes',
                  accountCodes
                ];

                this.salesCredits.data.lines[index].productable = true;
                this.salesCredits.data.lines[index].productable_account_source =
                  productableSource;
              }
            });
          });
      } else {
        this.getParentColumns().then(response => {
          this.salesCredits.data.parent = response;
          this.salesCredits.page.list = this.data.transaction_type;
          this.salesCredits.data.parent.parent_id = this.data.id;
          this.salesCredits.data.parent.transaction_to =
            this.data.transaction_to;
          this.salesCredits.data.parent.recorded_date = moment(
            this.data.recorded_date
          ).format('YYYY-MM-DD');
          this.salesCredits.data.parent.due_date = moment(
            this.data.due_date
          ).format('YYYY-MM-DD');
          this.salesCredits.data.parent.overdue_by = this.data.overdue_by;
          this.salesCredits.data.parent.invoice_and_statement_number =
            this.data.invoice_and_statement_number;
          this.salesCredits.data.parent.reference = this.data.reference;
          this.salesCredits.data.parent.project_id = this.data.project_id;
          this.salesCredits.data.parent.is_tax_exclusive = '0';
          this.taxRates.taxExclusive = '0';
          this.salesCredits.data.parent.transaction_type = 'products-used';
          this.salesCredits.data.parent.product_used_number =
            this.assignableNumber;
        });

        this.getLineColumns().then(response => {
          response.tax_rate_id = '4';
          this.salesCredits.data.lines = [response];
        });
      }
    },

    setAsCreditMemo () {
      if (typeof this.creditMemo !== 'undefined') {
        this.$store.dispatch('salesCredits/setDataToState', this.creditMemo);
      } else {
        this.getParentColumns().then(response => {
          this.salesCredits.data.parent = response;
          this.salesCredits.data.parent.parent_id = this.data.id;
          this.salesCredits.data.parent.transaction_to =
            this.data.transaction_to;
          this.salesCredits.data.parent.credit_memo_to =
            this.data.transaction_to;
          this.salesCredits.data.parent.recorded_date =
            moment().format('YYYY-MM-DD');
          this.salesCredits.data.parent.due_date =
            moment().format('YYYY-MM-DD');
          this.salesCredits.data.parent.credit_memo_date =
            moment().format('YYYY-MM-DD');
          this.salesCredits.data.parent.overdue_by = this.data.overdue_by;
          this.salesCredits.data.parent.invoice_and_statement_number =
            this.data.invoice_and_statement_number;
          this.salesCredits.data.parent.reference = this.data.reference;
          this.salesCredits.data.parent.project_id = this.data.project_id;
          this.salesCredits.data.parent.is_tax_exclusive = '1';
          this.salesCredits.data.parent.transaction_type = 'credit-memo';
          this.salesCredits.data.parent.credit_memo_number =
            this.assignableNumber;
        });

        this.getLineColumns().then(response => {
          this.salesCredits.data.lines = [response];
        });
      }
    }
  }
};
