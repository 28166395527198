<template>
  <button
    type="button"
    :class="class"
    data-bs-toggle="tooltip"
    data-bs-placement="bottom"
    title="Delete"
    @click="clickHandler"
  >
    <i class="fa fa-trash-o fs-6" aria-hidden="true"></i>
  </button>
</template>
<script>
  export default {
    name: 'HeaderDeleteButton',
    props: {
      class: {
        type: String,
        default: 'btn btn-light me-2 text-primary px-3'
      }
    },

    methods: {
      clickHandler() {
        this.$emit('clickEvent');
      }
    }
  };
</script>
<style></style>
