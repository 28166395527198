import { loadSharedResources } from './data/sharedResources.js';
import { initializeScrollableContainers } from './utils/perfect-scrollbar.js';
import { basicScrollableX } from './utils/perfect-scrollbar.js';
import { basicScrollableY } from './utils/perfect-scrollbar.js';

export const autoFunctionLoader = {
  install(app) {
    app.mixin(
      {
        props: {
          app: {
            type: String,
            default: this.pathResolver?.primaryPaths('app'),
          },
          tenant: {
            type: String,
            default: this.pathResolver?.primaryPaths('tenant'),
          },
          module: {
            type: String,
            default: this.pathResolver?.primaryPaths('module'),
          },
        },

        data() {
          return {
            // sharedResource: [],
          }
        },

        async mounted() {
          /**
           * @info Instantiates Perfect Scrollbar library for every scrolled elements
           */
          basicScrollableX();
          basicScrollableY();
          initializeScrollableContainers();

          /**
           * @info Loads shared resources for select input options (TO DO)
           */
          // this.sharedResource = await loadSharedResources();


          
        },
      }
    );
  },
};