import { pathResolver } from '@/functions/pathResolver.js';
import axiosRequests from '../../functions/axiosRequests';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');
let module = pathResolver.primaryPaths('module');

export default {
  namespaced: true,

  state: {
    taxExclusive: '',
    rates: [],
    rateSelectAllData: [],
  },

  actions: {
    getAllTaxRates({ commit, dispatch, state }, url) {
      return new Promise((resolve, reject) => {
        const headers = {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
        };
        
        axios.get(url, { headers })
        .then(async (response) => {
          state.rates = response.data
          resolve(response);
        })
        .catch(function (error) {
          console.log(`Error: ${error.response}`)
          if (error.response !== 200) {
            dispatch('getAllTaxRates', `/${app}/${tenant}/resources/tax_rates?module=${module}`)
          }
          reject();
        });
      });
    },
    
    async getTaxRatesSelect({ commit, dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        dispatch('getAllTaxRates', `/${app}/${tenant}/resources/tax_rates?module=${module}`)
        .then(async (response) => {
          if (state.taxExclusive !== undefined) {
            let taxList = [];
            
            if (parseInt(state.taxExclusive) === 0) {
              taxList = _.filter(response.data, function(data) { return data.code === 'no_tax'; });
            } else {
              taxList = _.filter(response.data, function(data) { return data.code !== 'no_tax'; });
            }
            
            state.rateSelectAllData = taxList
            
            const taxRates = taxList.map((res) => {
              return {
                id: res.id,
                value: `${res.name} (${Math.floor(res.percentage)})`
              };
            });
            
            resolve(taxRates);
          }
        })
        .catch(function (error) {
          console.log('error response ' + error)
          if (error.response !== 200) {
            window.location.reload()
          }
          reject();
        });
      });
    }
  },
};