import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { processors } from '../../../functions/processors';
import { formatter } from '@/functions/formatter';
import constants from '../../../constants';
import { sharedProps } from '@/functions/data/sharedResources';

let appPath = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    data: {
      collectionsParent: {},
      collectionsLines: [],
      groupCollections: []
    },
    parentValidations: [],
    lineValidations: [],
    collectionView: false,
    boardContent: null,
    collectionList: [],
    status: '',
    collectionIdShown: null
  },

  mutations: {
    setLine (state, { line, type }) {
      state.data.collectionsLines.push(line);
    }
  },

  actions: {
    async getParentColumns ({}) {
      return await axiosRequest.get(
        `/${appPath}/${tenant}/states/sales_collections`,
        { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
      );
    },

    async getLinesColumns ({}) {
      return await axiosRequest.get(
        `/${appPath}/${tenant}/states/sales_collection_lines`,
        { except: ['created_at', 'updated_at', 'deleted_at'] }
      );
    },

    async setLineColumns ({ dispatch, state, commit }, type) {
      let newLine = await dispatch('getLinesColumns');

      if (typeof type === 'undefined') {
        newLine.type = '';
      } else {
        newLine.type = type;
      }

      commit('setLine', { line: newLine, type: type });

      const lastIndex = state.data.collectionsLines.length - 1;

      return {
        index: lastIndex,
        line: newLine,
        lines: state.data.collectionsLines
      };
    },

    removeCollectionLine ({ state }, index) {
      state.data.collectionsLines.splice(index, 1);
    },

    async setGroupLine ({ dispatch, state }, type) {
      let parentColumns = await dispatch('getParentColumns');
      let lineColumns = await dispatch('getLinesColumns');

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('groupCode')) {
        parentColumns.group_code = urlParams.get('groupCode');
      }
      
      parentColumns = [parentColumns].map((parent, index) => {
        parent.recorded_date = '';
        parent.sale = {};
        parent.sale.grand_total = formatter.currency(0);
        parent.sale.total_tax = formatter.currency(0);
        parent.sale.total_collections_remaining = formatter.currency(0);
        
        if (type === 'statement') {
          parent.sale.is_tax_exclusive = true;
        }

        return parent;
      });

      let money = lineColumns;
      let prepaid = lineColumns;
      let vat = lineColumns;
      let realized = lineColumns;
      
      let newLine = [{
        money: money,
        prepaid: prepaid,
        vat: vat,
        realized: realized,
      }];
      
      let paymentsColumns = newLine.map((type, index) => {
        const moneyType = { ...type.money };
        moneyType.amount = 0;
        moneyType.base_tax_amount = 0;
        moneyType.deferred_tax_amount = 0;
        moneyType.type = constants.COLLECTION_TYPE.MONEY_COLLECTION;
        type.money = moneyType;

        const prepaidType = { ...type.prepaid };
        prepaidType.amount = 0;
        prepaidType.base_tax_amount = 0;
        prepaidType.deferred_tax_amount = 0;
        prepaidType.type = constants.COLLECTION_TYPE.PREPAID_TAX;
        type.prepaid = prepaidType;

        const vatType = { ...type.vat };
        vatType.amount = 0;
        vatType.base_tax_amount = 0;
        vatType.deferred_tax_amount = 0;
        vatType.type = constants.COLLECTION_TYPE.VAT_WITHHELD;
        type.vat = vatType;

        const realizedType = { ...type.realized };
        realizedType.amount = 0;
        realizedType.base_tax_amount = 0;
        realizedType.deferred_tax_amount = 0;
        realizedType.type = constants.COLLECTION_TYPE.REALIZED_VAT;
        type.realized = realizedType;

        return type
      });
      
      state.data.groupCollections.push({
        collectionsParent: parentColumns[0],
        collectionsLines: paymentsColumns[0]
      });
    },

    removeGroupLine ({ state }, index) {
      state.data.groupCollections.splice(index, 1);
      console.log(index)
    },

    setCollectionList ({ state }, collections) {
      collections.forEach(obj => {
        if (obj.lines) {
          obj.lines.sort((a, b) => {
            return parseInt(a.type) - parseInt(b.type);
          });
        }
      });

      state.collectionList = collections;
    },

    async setCollection ({ dispatch, commit, state }, params) {
      state.data.collectionsParent = params.data;

      state.data.collectionsParent.collection_date =
        params.data.collection_date;

      state.data.collectionsParent.receipt_type = params.data.receipt_type;

      state.data.collectionsParent.receipt_number_prefix =
        params.data.receipt_number_prefix;

      state.data.collectionsParent.receipt_number_series =
        params.data.receipt_number_series;

      state.data.collectionsParent.collection_total =
        params.data.collection_total;

      state.status = params.data.status;

      await dispatch('setLineColumns');

      const lines = params.data.lines.map((line, index) => {
        line.type = parseInt(line.type);
        return line;
      });

      state.data.collectionsLines = params.data.lines;
    },

    async getSaleById ({ dispatch, commit, state }, id) {
      const urlParams = new URLSearchParams(window.location.search);
      let groupCodeParam = '';
      if (urlParams.has('groupCode')) {
        groupCodeParam = `&groupCode=${urlParams.get('groupCode')}`;
      }

      const sharedInfo = sharedProps.get();
      let tenantId = sharedInfo.tenant;

      if (tenantId.hasOwnProperty('id')) {
          tenantId = sharedInfo.tenant?.id;
      }

      return await axiosRequest.get(
        `/${sharedInfo.app}/${tenantId}/sales/credit-sales/${id}?saleOnly=true${groupCodeParam}`
      );
    },

    async update ({ state, rootState }, params) {
      let status = '';
      if (params.status) {
        status = '?status=void'
      }

      return await axiosRequest.put(
        `/${appPath}/${tenant}/sales/credit-sales/${params.saleId}/collections/${params.id}${status}`,
        {
          parent: state.data.collectionsParent,
          lines: state.data.collectionsLines
        }
      ).then((response) => {
        if (response.message === 'invalid') {
          /**
           * @info Set validation boolean individual fields
           * */
          const parentErrors = response.parentErrors;
          let validatedParents = processors.validate(parentErrors);
          state.parentValidations = validatedParents.keys;

          /**
           * @info Set validation boolean for dynamic row fields
           * */
          const lineErrors = response.lineErrors;
          let validatedRows = processors.validateRows(
            Object.values(lineErrors)
          );
          state.lineValidations = validatedRows;

          setTimeout(function () {
            rootState.alerts.invalid = true;
          }, 1000);

          return response;
        } else {
          rootState.alerts.submitting = false;
          rootState.alerts.submitted = true;

          return response;
        }
      });
    },

    async delete ({}, params) {
      return await axiosRequest.delete(
        `/${appPath}/${tenant}/sales/credit-sales/${params.saleId}/collections/${params.id}`,
        params
      );
    },

    async submit ({ state, rootState }, sale) {
      return await axiosRequest
        .post(
          `/${appPath}/${tenant}/sales/credit-sales/${sale}/collections/store`,
          {
            parent: state.data.collectionsParent,
            lines: state.data.collectionsLines
          }
        )
        .then(response => {
          const myTimeout = setTimeout(function () {
            rootState.alerts.saving = false;
          }, 1000);

          if (response.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;

            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.lineErrors;
            let validatedRows = processors.validateRows(
              Object.values(lineErrors)
            );
            state.lineValidations = validatedRows;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response;
          } else {
            rootState.alerts.submitting = false;
            rootState.alerts.submitted = true;

            return response;
          }
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },

    async submitGroup ({ state, rootState }, param) {
      return await axiosRequest
        .post(
          `/${appPath}/${tenant}/sales/credit-sales/group-collections/store/${param.type}`,
          state.data.groupCollections
        )
        .then(response => {
          if (response.message === 'invalid') {
            /**
             * @info Set validation boolean individual fields
             * */
            const parentErrors = response.parentErrors;
            let validatedParents = processors.validate(parentErrors);
            state.parentValidations = validatedParents.keys;

            /**
             * @info Set validation boolean for dynamic row fields
             * */
            const lineErrors = response.lineErrors;
            state.lineValidations = lineErrors;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response.message;
          } else {
            rootState.alerts.submitting = false;
            rootState.alerts.submitted = true;

            return response;
          }
        })
        .catch(function (error) {
          console.log('has errors', error);
        });
    },

    async updateGroup ({ state, rootState }, param) {
      let qStr = `?status=${param.status}&formRequest=true`;
      if (typeof param.status === 'undefined') {
        qStr = `?formRequest=true`;
      }

      return await axiosRequest.put(
        `/${appPath}/${tenant}/sales/${param.type}/group-collections/${param.receiptType}/${param.groupCode}${qStr}`,
        state.data.groupCollections
      ).then((response) => {
        if (response.message === 'invalid') {
          /**
           * @info Set validation boolean individual fields
           * */
          const parentErrors = response.parentErrors;
          let validatedParents = processors.validate(parentErrors);
          state.parentValidations = validatedParents.keys;

          /**
           * @info Set validation boolean for dynamic row fields
           * */
          const lineErrors = response.lineErrors;
          state.lineValidations = lineErrors;

          setTimeout(function () {
            rootState.alerts.invalid = true;
          }, 1000);

          return response.message;
        } else {
          rootState.alerts.submitting = false;
          rootState.alerts.submitted = true;

          return response;
        }
      });
    },

    voidCollection ({ dispatch, rootState }, params) {
      rootState.alerts.submitting = true;

      dispatch('update', {
        saleId: params.saleId,
        id: params.id,
        status: 'void'
      }).then(response => {
        dispatch(
          'salesCredits/setDataToState',
          _.omit(response.data, ['collections']),
          { root: true }
        );
        dispatch('setCollectionList', response.data.collections);
        rootState.alerts.submitting = false;
      });
    },

    deleteCollection ({ dispatch, rootState }, params) {
      rootState.alerts.submitting = true;

      dispatch('delete', { saleId: params.saleId, id: params.id }).then(
        async response => {
          dispatch(
            'salesCredits/setDataToState',
            _.omit(response.data, ['collections']),
            { root: true }
          );
          dispatch('setCollectionList', response.data.collections);
          rootState.alerts.submitting = false;
        }
      );
    },

    async getPrepaidAccount({ dispatch }) {
      return await dispatch('accounts/getResourceBy', {
          url: `/${appPath}/${tenant}/resources/accounts?getAccounts=true&module=sales`,
          types: [{ account_codes: 'income_tax' }]
        }, { root: true })
        .then(async response => {
          return await response.data[0].id;
        });
    },
  }
};
