import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { processors } from '@/functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    page: {
      list: 'suppliers',
      form: false,
      autosave: false,
    },
    list: [],
    data: {},
    validations: [],
  },

  mutations: {
    setData(state, data) {
      const key = data.key;
      state.data[data.key] = data.value;
    },
  },

  actions: {
    async getColumns({}) {
      return await axiosRequest.get(
        `/${app}/${tenant}/states/contacts`,
        { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
      );
    },

    async setColumns({ dispatch, state }) {
      const columns = await dispatch('getColumns');
      state.data = columns;
    },

    async setDataToState({ dispatch, state, commit }, data) {
      if (typeof data !== 'undefined') {
        await dispatch('setColumns').then(() => {
          Object.entries(data).map((val) => {
            const key = val[0];
            const value = val[1];
            commit('setData', { key, value });
          })
        });
      }
    },

    async getContactById({}, id) {
      const contact = await axiosRequest.get(`/${app}/${tenant}/contacts/${parseInt(id)}?json=true`)
      return contact[0]
    },

    async update({ state, rootState }, id) {
      return await axiosRequest.put(`/${app}/${tenant}/contacts/${id}`, state.data)
        .then((response) => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);
          
          if (response.message === 'invalid') {
            const errors = response.errors;
            state.validations = errors;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response.message;
          }

          rootState.alerts.submitting = false;
          rootState.alerts.submitted = true;
          
          return response;
        });
    },

    delete({ commit, dispatch, state }, params) {
      let status = '';

      if (typeof params.status !== 'undefined') {
        status = '?isArchive=true';
      }

      return axiosRequest.delete(
        `/${app}/${tenant}/contacts/${params.contactId}${status}`
      );
    },

    async store({ state, rootState }) {
      return await axiosRequest.post(`/${app}/${tenant}/contacts/store`, state.data)
        .then((response) => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);
          
          if (response.message === 'invalid') {
            const errors = response.errors;
            state.validations = errors;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 1000);

            return response.message;
          }

          rootState.alerts.submitting = false;
          rootState.alerts.submitted = true;
          
          return response;
        });
    },
  },
};