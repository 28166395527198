import alerts from '../store/Module/alerts'

export const processors = {
  getAttributes() {
    return JSON.parse(app.dataset.page).props;
  },

  translate(path) {
    const paths = path.split('.');
    
    let value = this.$page.props.translations;

    for (const p of paths) {
      if (value.hasOwnProperty(p)) {
        value = value[p];
      } else {
        return path;
      }
    }

    return value;
  },

  getTabledFormValidation(response) {
    /**
     * @info Set validation boolean individual fields
     * */
    const parentErrors = response.data.parentErrors;
    let validatedParents = processors.validate(parentErrors);

    /**
     * @info Set validation boolean for dynamic row fields
     * */
    const lineErrors = response.data.lineErrors;
    let validatedRows = processors.validateRows(lineErrors);
    
    return {
      'parent': validatedParents.keys,
      'lines': validatedRows,
    };
  },

  validate(errors) {
    let res = {keys: '', messages: {}};

    if (typeof parentErrors !== 'undefined') {
      const errorKeys = Object.keys(errors);
    
      const keys = errorKeys.reduce((errorObj, errorKey) => {
        errorObj[errorKey] = true;
        return errorObj;
      }, {});
      
      const messages = {};
  
      Object.entries(errors).forEach(([key, [value]]) => {
        messages[key] = value;
      });
      
      res = {
        keys: keys,
        messages: messages
      }; 
    }
    
    return res;
  },

  validateRows(rowKeys) {
    if (Array.isArray(rowKeys)) {
      const rowKeysWithErrors = rowKeys.map((errors, index) => {
        const errorKeys = Object.keys(errors);
        
        return errorKeys.reduce((errorObj, errorKey) => {
          errorObj[errorKey] = true;
          return errorObj;
        }, {})
      });
      
      return rowKeysWithErrors;
    } else if (typeof rowKeys === 'object' && rowKeys !== null) {
      const retainedKeys = Object.keys(rowKeys).reduce((error, key) => {
        error[key] = rowKeys[key];
        return error;
      }, []);
      
      return retainedKeys;
    } else {
      console.error('Invalid input for rowKeys. Expected array or object.');
      return [];
    }
  },
};