import { pathResolver } from "@/functions/pathResolver.js";
import axiosRequest from '@/functions/axiosRequests.js';
import { processors } from "@/functions/processors";

let app = pathResolver.primaryPaths("app");
let tenant = pathResolver.primaryPaths("tenant");

export default {
  namespaced: true,

  state: {
    data: [],
    fieldValidations: [],
    fieldValidationMessages: [],
  },

  actions: {
    async getAccountColumns({ commit, dispatch, state }) {
      return await axiosRequest.get(`/${app}/${tenant}/states/cash_accounts`, {
        except: ['id', 'created_at', 'updated_at', 'deleted_at']
      });
    },

    async getTransactionColumns({ commit, dispatch, state }) {
      return await axiosRequest.get(`/${app}/${tenant}/states/cash_transactions`, {
        except: ['id', 'created_at', 'updated_at', 'deleted_at']
      });
    },

    async getTransactionLineColumns({ commit, dispatch, state }) {
      return await axiosRequest.get(`/${app}/${tenant}/states/cash_transaction_lines`, {
        except: ['id', 'created_at', 'updated_at', 'deleted_at']
      });
    },

    async setAccountDataToState({ dispatch, state }, data) {
      const columns = await dispatch('getAccountColumns');
      
      if (data) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            columns[key] = data[key];
          }
        }
      }
      
      columns.currency = 'PHP';
      state.data = columns;
    },

    storeAccount({ dispatch, state, rootState }) {
      rootState.alerts.submitting = true;
      const data = state.data;
      
      axiosRequest.post(`/${app}/${tenant}/cash`, data)
        .then((response) => {
          if (response.status === 'invalid') {
            const validatedFields = processors.validate(response.errors);
            state.fieldValidations = validatedFields.keys;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.submitting = false

            setTimeout(function () {
              rootState.alerts.invalid = false
            }, 5000);
          }

          if (response.status === 'chart-account-invalid') {
            setTimeout(function () {
              rootState.alerts.customMessageError = 
                [true, 'Cannot save cash account to Chart of Accounts. Please try again or inform Administrator']
            }, 500); 
          }

          if (response.status === 'success') {
            setTimeout(function () {
              rootState.alerts.submitting = false
            }, 500);

            window.location.href = `/${app}/${tenant}/cash`
          }
        });
    },

    updateAccount({ dispatch, state, rootState }) {
      rootState.alerts.submitting = true;

      const data = state.data;
      axiosRequest.put(`/${app}/${tenant}/cash/${data.id}`, data)
        .then((response) => {
          if (response.status === 'invalid') {
            const validatedFields = processors.validate(response.errors);
            state.fieldValidations = validatedFields.keys;

            setTimeout(function () {
              rootState.alerts.invalid = true
            }, 500);

            rootState.alerts.saving = false

            setTimeout(function () {
              rootState.alerts.invalid = false
            }, 5000);
          }

          if (response.status === 'chart-account-invalid') {
            setTimeout(function () {
              rootState.alerts.customMessageError = 
                [true, 'Cannot save bank account to Chart of Accounts. Please try again or inform Administrator']
            }, 500); 
          }

          if (response.status === 'success') {
            setTimeout(function () {
              rootState.alerts.submitting = false
            }, 500);

            window.location.href = `/${app}/${tenant}/cash`
          }
        });
    },
    
    async getCashAccounts({}, params) {
      return await axiosRequest.get(`/${app}/${tenant}/resources/cash_accounts`)
        .then(response => {
          return response;
        });
    },
  },
};