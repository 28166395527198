import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      // isLoadingTotals: true,
    }
  },
  
  computed: {
    ...mapState(['billsToPay', 'alerts']),

    contactState: {
      get() {
        return this.billsToPay.data.parent.contact_id;
      }
    },

    dateState: {
      get() {
        return this.billsToPay.data.parent[this.name];
      },
      set(value) {
        this.billsToPay.data.parent[this.name] = moment(value).format('YYYY-MM-DD');
      }
    },

    transactionNumber: {
      get() {
        return this.billsToPay.data.parent.transaction_number;
      },
      set(value) {
        this.billsToPay.data.parent.transaction_number = value;
      }
    },

    referenceState: {
      get() {
        return this.billsToPay.data.parent.reference;
      },
      set(value) {
        this.billsToPay.data.parent.reference = value;
      }
    },

    projectState: {
      get() {
        return this.billsToPay.data.parent.project_id
      }
    },

    isTaxExclusiveState: {
      get() {
        return this.billsToPay.data.parent.is_tax_exclusive
      },
      set(value) {
        this.billsToPay.data.parent.is_tax_exclusive = value
        
        this.billsToPay.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.billsToPay.data.lines[index].tax_rate_id = '4'
          } else {
            this.billsToPay.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value;
        
        this.billsToPay.data.lines.map((line) => {
          line.withholding_tax_rate_id = '';
        })

        this.$store.dispatch('billsToPay/setTaxAmounts');
        this.$store.dispatch('billsToPay/setTotals');
        this.setGrandTotals();
      }
    },
  },

  watch: {
    'billsToPay.data.parent.transaction_date'(newVal, oldVal) {
      this.changeTermByDates(
        'transaction_date',
        this.billsToPay.data.parent.due_date,
        newVal
      );
    },

    'billsToPay.data.parent.due_date'(newVal, oldVal) {
      this.changeTermByDates(
        'due_date',
        this.billsToPay.data.parent.system_recorded_date,
        newVal
      );
    },
  },

  methods: {
    changeContactSelect(index, name, value) {
      this.billsToPay.data.parent.contact_id = value
      this.clearParentInvalids()
      this.clearLineInvalids()
      this.alerts.invalid = false;
    },

    changeTermSelect(index, name, value) {
      const billDate = this.billsToPay.data.parent.transaction_date;
      const newDueDate = moment(billDate)
        .add(value, 'days').format('D MMM YYYY');

      this.changeDueByTerms(newDueDate);
    },

    changeDueByTerms(newDueDate) {
      this.billsToPay.data.parent.due_date = moment(newDueDate).format('YYYY-MM-DD');
    },

    changeTermByDates(field, otherField, newValue) {
      const billDate = this.billsToPay.data.parent.transaction_date
      const dueDate = this.billsToPay.data.parent.due_date

      const startDate =
        field === 'transaction_date'
          ? moment(newValue)
          : moment(billDate);

      const endDate =
        field === 'due_date'
          ? moment(newValue)
          : moment(dueDate);

      const newTerms = endDate.diff(startDate, 'days')
      this.selectedTerm = newTerms || 0;
      this.billsToPay.data.parent.overdue_by = newTerms;
    },

    changeProjectSelect(index, name, value) {
      this.billsToPay.data.parent.project_id = value
    },

    changeDynamicTableField(index, name, value) {
      if(
        ![
          'account_id',
          'tax_rate_id',
          'withholding_tax_rate_id',
        ].includes(name)
      ) {
        this.clearLineInvalids();

        if (
          [
            'unit_price',
            'sub_total',
            'tax_amount',
            'total_amount'
          ].includes(name)
        ) {
          /** Clean currency value */
          value = value?.replace(/,/g, '');
        }
        
        this.billsToPay.data.lines[index][name] = value;
        this.$store.dispatch('billsToPay/setSubTotals')
        this.$store.dispatch('billsToPay/setTaxAmounts')
        this.$store.dispatch('billsToPay/setTotals')
        this.setGrandTotals()
      }
    },

    changeDynamicTableSelectField(index, name, value) {
      if (typeof name !== 'undefined') {
        this.clearLineInvalids()
        this.billsToPay.data.lines[index][name] = value;
        this.$store.dispatch('billsToPay/setTaxAmounts')
        this.$store.dispatch('billsToPay/setTotals')
        
        if(['tax_rate_id', 'withholding_tax_rate_id'].includes(name)) {
          this.setGrandTotals();
        }
      }
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.billsToPay.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids() {
      const lines = this.billsToPay.data.lines.map((line, index) => {
        delete line['invalid'];
        return line
      })

      this.billsToPay.data.lines = lines;
      this.alerts.invalid = false;
    },

    clearParentInvalids() {
      this.billsToPay.invalidParents = []
      this.alerts.invalid = false;
    },

    setNewLine() {
      this.$store.dispatch('billsToPay/setNewLine', {tab: this.tab, data: this.data})
    },

    deleteLine(id, index) {
        this.$store.dispatch('billsToPay/deleteLine', {tab: this.tab, id: id, index: index})
    },

    setGrandTotals() {
      // On load, get default tax rates
      this.$store.dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.billsToPay.data.parent.is_tax_exclusive
        })
        .then(response => {
          this.$store.dispatch('billsToPay/setGrandTotals');
        })
        .catch(error => { console.log(error) })
    },

    async setDataWithholding() {
      const taxWithheld = await this.data?.withholding_tax?.grand_withheld;
      this.billsToPay.taxWithheld = taxWithheld;
    },
  },
};