export const pathResolver = {
  primaryPaths(key) {
    let paths = {
      'app': JSON.parse(app.dataset.page).props.app,
      'tenant': JSON.parse(app.dataset.page).props.tenant,
      'module': JSON.parse(app.dataset.page).props.module,
      'tab': JSON.parse(app.dataset.page).props.tab,
    };
    
    return paths[key];
  },

  primaryUrlWithModule() {
    const app = this.primaryPaths('app');
    const tenant = this.primaryPaths('tenant');
    const module = this.primaryPaths('module');

    return `/${app}/${tenant}/${module}`;
  },

  primaryUrlWithoutModule() {
    const app = this.primaryPaths('app');
    const tenant = this.primaryPaths('tenant');

    return `/${app}/${tenant}`;
  }
}