export const customProgressBar = {
  // Function to show the custom loading bar
  showCustomProgressBar() {
    const container = document.createElement('div');
    container.className = 'custom-progress-container';
    const progress = document.createElement('div');
    progress.className = 'custom-progress-bar';
    container.appendChild(progress);
    document.body.appendChild(container);

    const progressBar = document.querySelector('.custom-progress-bar');
    if (progressBar) {
        progressBar.classList.add('active');
    }
  },

  // Function to hide the custom loading bar
  hideCustomProgressBar() {
    const container = document.querySelector('.custom-progress-container');
    if (container) {
        document.body.removeChild(container);
    }
  },
  
  reload(newUrl) {
    this.showCustomProgressBar();

    setTimeout(() => {
      window.history.pushState({ path: newUrl }, '', newUrl);
      this.hideCustomProgressBar();
    }, 1000); 
  }
};