<template>
  <Link
    :class="class"
    :href="href"
    :dataTable="dataTable"
    @click="clickHandler($event)"
  >
    <slot></slot>
  </Link>
</template>
<script>
  import { Link } from '@inertiajs/inertia-vue3';

  export default {
    name: 'CustomLink',
    props: {
      href: String,
      dataTable: {
        type: String,
        default: null,
      },
      class: {
        type: String,
        default: 'btn btn-light ml-auto text-dark'
      },
    },

    components: {
      Link
    },

    methods: {
      clickHandler(e) {
        this.$store.state.alerts.loading = true;
        
        if (this.dataTable) {
          $(`#${this.dataTable}`).DataTable().destroy();
        }
      },
    },
  }
</script>
<style>
  
</style>