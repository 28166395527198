import { mapState } from 'vuex';
import selectResources from "@/mixins/selectResources";
import alerts from "@/mixins/alerts";

export default {
  mixins: [selectResources, alerts],

  data() {
    return {
      //
    }
  },

  computed: {
    ...mapState(['loans', 'taxRates', 'alerts']),

    contactState: {
      get() {
        return this.loans.data.parent.contact_id;
      },
      set(value) {
        this.loans.data.parent.contact_id = value;
      }
    },

    dateState: {
      get() {
        return this.loans.data.parent[this.name];
      },
      set(value) {
        this.loans.data.parent[this.name] = moment(value).format('YYYY-MM-DD');
      }
    },

    transactionNumber: {
      get() {
        return this.loans.data.parent.transaction_number;
      },
      // set(value) {
      //   this.loans.data.parent.transaction_number = value;
      // }
    },

    referenceState: {
      get() {
        return this.loans.data.parent.reference;
      },
      set(value) {
        this.loans.data.parent.reference = value;
      }
    },

    projectState: {
      get() {
        return this.loans.data.parent.project_id
      },
    },

    isTaxExclusiveState: {
      get() {
        return this.loans.data.parent.is_tax_exclusive;
      },
      set(value) {
        this.loans.data.parent.is_tax_exclusive = value
        
        this.loans.data.lines.map((line, index) => {
          if (parseInt(value) === 0) {
            this.loans.data.lines[index].tax_rate_id = '4'
          } else {
            this.loans.data.lines[index].tax_rate_id = '1'
          }
        });
        
        this.$store.state.taxRates.taxExclusive = value;
        
        this.loans.data.lines.map((line) => {
          line.withholding_tax_rate_id = '';
        })

        this.$store.dispatch('loans/setTaxAmounts');
        this.$store.dispatch('loans/setTotals');
        this.setGrandTotals();
      }
    },
  },

  watch: {
    'loans.data.parent.recorded_date'(newVal, oldVal) {
      this.changeTermByDates(
        'recorded_date',
        this.loans.data.parent.due_date,
        newVal
      );
    },

    'loans.data.parent.due_date'(newVal, oldVal) {
      this.changeTermByDates(
        'due_date',
        this.loans.data.parent.recorded_date,
        newVal
      );
    },
  },

  methods: {
    changeContactSelect(index, name, value) {
      this.loans.data.parent.contact_id = value;
      this.setNumberOptions(value);
    },

    setNumberOptions(contactId) {
      const optionsByContact = this.loans.loanNumbers.filter((number) => {
        return number.contact_id === contactId;
      }).map(num => {
        return {
          id: num.id,
          value: num.number
        };
      });

      this.loans.loanOptionNumber = optionsByContact;
    },

    changeProjectSelect(index, name, value) {
      this.loans.data.parent.project_id = value
    },

    changeTermSelect(index, name, value) {
      const newDueDate = moment(this.loans.data.parent.recorded_date)
        .add(value, 'days')
        .format('D MMM YYYY');

      this.changeDueByTerms(newDueDate);
    },

    changeNumberSelect(index, name, value) {
      this.loans.data.parent.transaction_number = value;
    },

    changeDueByTerms(newDueDate) {
      this.loans.data.parent.due_date = moment(newDueDate).format('YYYY-MM-DD');
    },

    changeTermByDates(field, otherField, newValue) {
      const startDate =
        field === 'recorded_date'
          ? moment(newValue)
          : moment(this.loans.data.parent.recorded_date)

      const endDate =
        field === 'due_date'
          ? moment(newValue)
          : moment(this.loans.data.parent.due_date)

      const newTerms = endDate.diff(startDate, 'days')
      this.selectedTerm = newTerms;

      this.loans.data.parent.overdue_by = newTerms;
    },

    changeDynamicTableField(index, name, value) {
      if(!['account_id', 'tax_rate_id', 'withholding_tax_rate_id'].includes(name)) {
        this.clearLineInvalids()

        if (
          [
            'unit_price',
            'sub_total',
            'tax_amount',
            'total_amount'
          ].includes(name)
        ) {
          /** Clean currency value */
          value = value?.replace(/,/g, '');
        }

        this.loans.data.lines[index][name] = value;
        this.$store.dispatch('loans/setSubTotals')
        this.$store.dispatch('loans/setTaxAmounts')
        this.$store.dispatch('loans/setTotals')
        this.setGrandTotals(index, name)
      }
    },

    changeDynamicTableSelectField(index, name, value) {
      this.clearLineInvalids()
      this.loans.data.lines[index][name] = value;
      this.$store.dispatch('loans/setTaxAmounts');
      this.$store.dispatch('loans/setTotals');
      
      if(['tax_rate_id', 'withholding_tax_rate_id'].includes(name)) {
        this.setGrandTotals();
      }
    },

    setGrandTotals(index, name) {
      // On load, get default tax rates
      this.$store.dispatch('taxRates/getTaxRatesSelect', {
          taxExclusive: this.loans.data.parent.is_tax_exclusive
        })
        .then(() => {
          this.$store.dispatch('loans/setGrandTotals')
        })
        .catch(error => { console.log(error) })
    },

    isDynamicLineInvalid(index, name) {
      const lineValidations = this.loans.lineValidations;
      
      if (Array.from(lineValidations).length > 0) {
        const validation = lineValidations[index];
        return validation && validation[name];
      }
    },

    clearLineInvalids() {
      const lines = this.loans.data.lines.map((line, index) => {
        delete line['invalid'];
        return line
      })

      this.loans.data.lines = lines;
      this.alerts.invalid = false;
    },

    clearParentInvalids() {
      this.loans.invalidParents = []
      this.alerts.invalid = false;
    },
  },
}