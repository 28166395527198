export default {
  async get(url, params) {
    return await new Promise((resolve, reject) => {
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
      };
      
      axios.get(url, { params: params, headers: headers })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log('axios get error:', error);
          if (error.response !== 200) {
            if (JSON.parse(app.dataset.page).props.app_env !== 'local') {
              window.location.reload();
            }
          }
          reject();
        });
    });
  },

  async post(url, params) {
    return await new Promise((resolve, reject) => {
      const config = params ? Object.assign({}, params) : {};

      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
      };

      axios.post(url, config, { headers })
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log('axios post error:', error);
          if (error.response !== 200) {
            if (JSON.parse(app.dataset.page).props.app_env !== 'local') {
              window.location.reload();
            }
          }
          reject();
        });
    });
  },

  async put(url, params) {
    return await new Promise((resolve, reject) => {
      const config = params ? Object.assign({}, params) : {};
      axios.put(url, config)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log('axios put error:', error);
          if (error.response !== 200) {
            if (JSON.parse(app.dataset.page).props.app_env !== 'local') {
              window.location.reload();
            }
          }
          reject();
        });
    });
  },

  async delete(url, params) {
    return await new Promise((resolve, reject) => {
      const config = params ? Object.assign({}, params) : {};
      axios.delete(url, config)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log('axios delete error:', error);
          if (error.response !== 200) {
            if (JSON.parse(app.dataset.page).props.app_env !== 'local') {
              window.location.reload();
            }
          }
          reject();
        });
    });
  }
}
